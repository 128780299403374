<script>
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import transform from 'lodash/transform';
import set from 'lodash/set';
import isObject from 'lodash/isObject';
import keys from 'lodash/keys';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';

import { sentenceCase } from '@emobg/web-utils';
import { ContentCellComponent } from '@/components';

export default {
  name: 'BookingAuditComponent',
  components: {
    ContentCellComponent,
  },
  props: {
    before: {
      type: Object,
      default: () => ({}),
    },
    after: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    areSameValues() {
      return isEqual(this.before, this.after);
    },
  },
  methods: {
    sentenceCase,
    isEmpty,
    changes(afterValue, beforeValue) {
      const after = cloneDeep(afterValue || {});
      const before = cloneDeep(beforeValue || {});
      return transform(after, (result, value, key) => {
        if (!isEqual(value, before[key])) {
          set(result, key, (isObject(value) && isObject(before[key])) ? this.changes(value, before[key]) : value);
        }
      });
    },
    changedItem(item) {
      const keysToPick = keys(this.changes(this.after, this.before));
      return pick(item, keysToPick);
    },
  },
};
</script>

<template>
  <div
    class="BookingAuditComponent"
    style="overflow-wrap: break-word;"
    data-test-id="booking_audits"
  >
    <div class="row">
      <div
        class="col-6"
        data-test-id="before_values"
      >
        <div class="emobg-caption-2 pb-2 emobg-color-ink">
          Before
        </div>
        <div v-if="isEmpty(before)">
          {{ FALLBACK_MESSAGE.dash }}
        </div>
        <div v-else-if="areSameValues">
          No data change registered
        </div>
        <div
          v-for="(value, key) in changedItem(before)"
          :key="`${key}_before`"
        >
          <ContentCellComponent
            v-if="value"
            :label="sentenceCase(key)"
            :value="value"
            class="mb-3"
          />
        </div>
      </div>
      <div
        class="col-6"
        data-test-id="after_values"
      >
        <div class="emobg-caption-2 pb-2 emobg-color-ink">
          After
        </div>
        <div v-if="isEmpty(after)">
          {{ FALLBACK_MESSAGE.dash }}
        </div>
        <div v-else-if="areSameValues">
          No data change registered
        </div>
        <div
          v-for="(value, key) in changedItem(after)"
          :key="`${key}_after`"
        >
          <ContentCellComponent
            v-if="value"
            :label="sentenceCase(key)"
            :value="value"
            class="mb-3"
          />
        </div>
      </div>
    </div>
  </div>
</template>
