import { COLORS, GRAYSCALE } from '@emobg/vue-base';

export const ADDITIONAL_COSTS_STATUS = {
  created: 'created',
  pending: 'pending',
};

export const SETTLEMENT_STATUS = {
  pendingBilling: 'pending_billing',
  billed: 'billed',
  refunded: 'refunded',
};

export const SETTLEMENT_STATUS_COLORS = {
  [SETTLEMENT_STATUS.pendingBilling]: GRAYSCALE.ground,
  [SETTLEMENT_STATUS.billed]: COLORS.success,
  [SETTLEMENT_STATUS.refunded]: COLORS.warning,
};
