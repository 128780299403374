var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-test-id": "booking_details_invoices-view" } },
    [
      _c("h1", { staticClass: "mb-2" }, [_vm._v(" Invoices ")]),
      _vm.algoliaIndex
        ? _c("MuiAlgoliaList", {
            attrs: {
              index: _vm.algoliaIndex,
              filters: `cs_booking_fk:${_vm.get(_vm.booking, "id")}`,
              facets: _vm.algoliaFacets,
              "table-config": _vm.algoliaConfig,
              "data-test-id": "list",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }