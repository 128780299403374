<script>
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import reduce from 'lodash/reduce';
import replace from 'lodash/replace';
import without from 'lodash/without';
import includes from 'lodash/includes';
import concat from 'lodash/concat';

import { mapActions, mapState } from 'vuex';
import {
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { sentenceCase } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  ContentCellComponent,
  GenericModalComponent,
  StoreNotificationComponent,
} from '@/components';
import { BOOKING_DETAIL_SCOPES } from '../../store/BookingDetailsModule';
import FraudCostsFormComponent from './FraudCostsFormComponent';

export default {
  name: 'FraudFormComponent',
  components: {
    CancelButton,
    ContentCellComponent,
    FraudCostsFormComponent,
    GenericModalComponent,
    MuiValidationWrapper,
    StoreNotificationComponent,
  },
  directives: {
    Validate,
  },
  props: {
    fraud: {
      type: Object,
      default: null,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: true,
      inputs: {
        reasons: [],
        comment: '',
        costs: [],
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      currencySymbol: state => state.operators.configuration.currencySymbol,
    }),
    ...mapState(DOMAINS_MODEL.carsharing.bookings.details, {
      booking: state => state.data,
      fraudValues: state => state[BOOKING_DETAIL_SCOPES.fraudValues].data,
      isFraudValuesLoading: state => state[BOOKING_DETAIL_SCOPES.fraudValues].STATUS.LOADING,
      fraudFormStatus: state => state[BOOKING_DETAIL_SCOPES.fraudForm].STATUS,
    }),
    fraudReasons() {
      return get(this.fraudValues, 'reasons', []);
    },
    isEdit() {
      return !!this.fraud;
    },
    totalCost() {
      const total = reduce(
        this.inputs.costs,
        (totalAccumulator, cost) => totalAccumulator + parseFloat(replace(cost.cost, ',', '.')),
        0,
      );

      return `${replace(total.toFixed(2), '.', ',')}${this.currencySymbol}`;
    },
    areReasonsFilled() {
      return !isEmpty(this.inputs.reasons);
    },
    areFieldsChanged() {
      return !isEqual(this.inputs, this.originalInputs);
    },
  },
  async created() {
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.BOOKING_DETAIL_SCOPES = BOOKING_DETAIL_SCOPES;

    if (isEmpty(this.fraudValues)) {
      await this.getBookingFraudValues();
    }

    if (this.fraud) {
      this.inputs.reasons = cloneDeep(this.fraud.reasons);
      this.inputs.costs = cloneDeep(this.fraud.costs);
      this.inputs.comment = this.fraud.comment;
    }
    this.originalInputs = cloneDeep(this.inputs);
  },
  methods: {
    sentenceCase,
    includes,
    without,
    concat,
    ...mapActions(DOMAINS_MODEL.carsharing.bookings.details, [
      'getBookingFraudValues',
      'postBookingFraud',
      'putBookingFraud',
    ]),
    async fraudRequest() {
      const request = this.isEdit ? this.putBookingFraud : this.postBookingFraud;

      await request({
        data: this.inputs,
        bookingUuid: this.booking.uuid,
      });

      if (!this.fraudFormStatus.ERROR) {
        const message = this.isEdit
          ? 'Fraud edited'
          : 'Fraud reported';
        this.$emit('closeModal');
        this.$notify({
          message,
          textAction: '',
        });
        this.onSuccess();
      }
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :title="isEdit ? 'Edit fraud' : 'Report fraud'"
    class="FraudFormComponent"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.carsharing.bookings.details"
        :scope="BOOKING_DETAIL_SCOPES.fraudForm"
        :action="isEdit ? 'Save' : 'Report fraud'"
      />
    </template>
    <template #body>
      <ui-loader
        v-if="isFraudValuesLoading"
        label="Loading fraud values..."
      />
      <MuiValidationWrapper
        v-else
        :extra-conditions="[areReasonsFilled, areFieldsChanged]"
        @areAllValid="isValid => isFormValid = isValid"
      >
        <ContentCellComponent
          :value="`#${booking.id}`"
          label="Booking"
        />
        <ContentCellComponent
          label="Fraud reason*"
          class="d-inline-flex flex-column mt-4"
        >
          <ui-checkbox
            v-for="reason in fraudReasons"
            :key="reason"
            :value="reason"
            :values.prop="inputs.reasons"
            :caption="sentenceCase(reason)"
            class="d-block my-1"
            @changevalue="({ detail }) => inputs.reasons = includes(inputs.reasons, detail) ? without(inputs.reasons, detail) : concat(inputs.reasons, detail)"
          />
        </ContentCellComponent>

        <ui-text-area
          :value="inputs.comment"
          placeholder="Add more details"
          label="Comment"
          name="comment"
          rows="4"
          class="mt-4"
          @changevalue="({ detail }) => inputs.comment = detail"
        />

        <FraudCostsFormComponent
          v-model="inputs.costs"
          class="mt-4"
        />
        <ContentCellComponent
          :value="totalCost"
          label="Total fraud cost"
          class="mt-4"
        />
      </MuiValidationWrapper>
    </template>
    <template #footer>
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton @click="$emit('closeModal')" />
        <ui-button
          :disabled="!isFormValid || fraudFormStatus.LOADING"
          :loading="fraudFormStatus.LOADING"
          class="wmin-initial"
          @clickbutton="fraudRequest"
        >
          {{ isEdit ? 'Save' : 'Report' }}
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
