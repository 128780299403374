<script>
import get from 'lodash/get';

import { MuiAlgoliaList } from '@emobg/motion-ui';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { TIME_ZONE } from '@emobg/web-utils';
import { mapState } from 'vuex';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { contentCells } from './config/contentCells';

export default {
  components: {
    MuiAlgoliaList,
  },

  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone || TIME_ZONE.default,
    }),
    ...mapState(DOMAINS_MODEL.carsharing.bookings.details, {
      booking: state => state.data,
    }),
  },
  created() {
    this.algoliaIndex = ALGOLIA_INDEXES.invoices;
    this.algoliaConfig = contentCells({
      operatorTimezone: this.operatorTimezone,
    });
    this.algoliaFacets = [];
  },
  methods: {
    get,
  },
};
</script>
<template>
  <div data-test-id="booking_details_invoices-view">
    <h1 class="mb-2">
      Invoices
    </h1>
    <MuiAlgoliaList
      v-if="algoliaIndex"
      :index="algoliaIndex"
      :filters="`cs_booking_fk:${get(booking, 'id')}`"
      :facets="algoliaFacets"
      :table-config="algoliaConfig"
      data-test-id="list"
    />
  </div>
</template>
