<script>
import get from 'lodash/get';
import { mapState } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { TIME_ZONE } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import CasesRoutes from '../../../../CasesServices/router/CasesServicesRouterMap';
import {
  caseEventsFacets,
  contentCells,
} from './config/contentCells';
export default {
  components: {
    MuiAlgoliaList,
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone || TIME_ZONE.default,
    }),
    ...mapState(DOMAINS_MODEL.carsharing.bookings.details, {
      booking: state => state.data,
    }),
  },
  created() {
    this.algoliaIndex = ALGOLIA_INDEXES.caseEvents;
    this.algoliaConfig = contentCells({
      operatorTimezone: this.operatorTimezone,
    });
    this.caseEventsFacets = caseEventsFacets.bind(this)();
  },
  methods: {
    get,
    goToCreateCase() {
      this.$router.push({
        name: CasesRoutes.caseEvents.create,
        query: {
          entity: 'booking',
          entityUUID: this.booking.uuid,
        },
      });
    },
  },
};
</script>
<template>
  <div data-test-id="booking_details_cases-view">
    <div class="d-flex align-items-center mb-2">
      <h1 class="flex-grow-1">
        Cases
      </h1>
      <ui-button
        data-test-id="create-button"
        @clickbutton="goToCreateCase"
      >
        Create case
      </ui-button>
    </div>
    <MuiAlgoliaList
      v-if="algoliaIndex"
      :index="algoliaIndex"
      :filters="`booking_fk:${get(booking, 'id')}`"
      :facets="caseEventsFacets"
      :table-config="algoliaConfig"
      :export-columns="algoliaConfig"
      data-test-id="list"
      is-export-enabled
    />
  </div>
</template>
