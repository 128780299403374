var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "FraudFormComponent",
        attrs: {
          header: { isClosable: true },
          title: _vm.isEdit ? "Edit fraud" : "Report fraud",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain":
                      _vm.DOMAINS_MODEL.carsharing.bookings.details,
                    scope: _vm.BOOKING_DETAIL_SCOPES.fraudForm,
                    action: _vm.isEdit ? "Save" : "Report fraud",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm.isFraudValuesLoading
                  ? _c("ui-loader", {
                      attrs: { label: "Loading fraud values..." },
                    })
                  : _c(
                      "MuiValidationWrapper",
                      {
                        attrs: {
                          "extra-conditions": [
                            _vm.areReasonsFilled,
                            _vm.areFieldsChanged,
                          ],
                        },
                        on: {
                          areAllValid: (isValid) => (_vm.isFormValid = isValid),
                        },
                      },
                      [
                        _c("ContentCellComponent", {
                          attrs: {
                            value: `#${_vm.booking.id}`,
                            label: "Booking",
                          },
                        }),
                        _c(
                          "ContentCellComponent",
                          {
                            staticClass: "d-inline-flex flex-column mt-4",
                            attrs: { label: "Fraud reason*" },
                          },
                          _vm._l(_vm.fraudReasons, function (reason) {
                            return _c("ui-checkbox", {
                              key: reason,
                              staticClass: "d-block my-1",
                              attrs: {
                                value: reason,
                                caption: _vm.sentenceCase(reason),
                              },
                              domProps: { values: _vm.inputs.reasons },
                              on: {
                                changevalue: ({ detail }) =>
                                  (_vm.inputs.reasons = _vm.includes(
                                    _vm.inputs.reasons,
                                    detail
                                  )
                                    ? _vm.without(_vm.inputs.reasons, detail)
                                    : _vm.concat(_vm.inputs.reasons, detail)),
                              },
                            })
                          }),
                          1
                        ),
                        _c("ui-text-area", {
                          staticClass: "mt-4",
                          attrs: {
                            value: _vm.inputs.comment,
                            placeholder: "Add more details",
                            label: "Comment",
                            name: "comment",
                            rows: "4",
                          },
                          on: {
                            changevalue: ({ detail }) =>
                              (_vm.inputs.comment = detail),
                          },
                        }),
                        _c("FraudCostsFormComponent", {
                          staticClass: "mt-4",
                          model: {
                            value: _vm.inputs.costs,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "costs", $$v)
                            },
                            expression: "inputs.costs",
                          },
                        }),
                        _c("ContentCellComponent", {
                          staticClass: "mt-4",
                          attrs: {
                            value: _vm.totalCost,
                            label: "Total fraud cost",
                          },
                        }),
                      ],
                      1
                    ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center justify-content-sm-end align-items-center",
                  },
                  [
                    _c("CancelButton", {
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closeModal")
                        },
                      },
                    }),
                    _c(
                      "ui-button",
                      {
                        staticClass: "wmin-initial",
                        attrs: {
                          disabled:
                            !_vm.isFormValid || _vm.fraudFormStatus.LOADING,
                          loading: _vm.fraudFormStatus.LOADING,
                        },
                        on: { clickbutton: _vm.fraudRequest },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.isEdit ? "Save" : "Report") + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }