import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import isBoolean from 'lodash/isBoolean';
import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  sentenceCase,
} from '@emobg/web-utils';
import moment from 'moment-timezone';

export const nameToShow = result => {
  const name = get(result, 'meta.name');
  const answer = get(result, 'data.answer');

  if (isNumber(answer)) {
    return `(${answer}/4)`;
  }
  if (isBoolean(answer)) {
    return sentenceCase(answer);
  }
  return name || FALLBACK_MESSAGE.dash;
};

export const formatDate = (date, operatorTimezone) => {
  if (!date) {
    return FALLBACK_MESSAGE.dash;
  }
  const momentDate = moment.tz(date, 'YYYY-MM-DDTHH:mm:ssZ', operatorTimezone);
  return momentDate.isValid() ? momentDate.format(DATE_FORMAT.defaultExtended) : FALLBACK_MESSAGE.dash;
};

export const facets = [
  {
    type: 'refinementList',
    props: {
      title: 'Name',
      attributeName: 'meta.name',
      transformResult: nameToShow,
    },
  },
];

export const contentCells = ({ operatorTimezone }) => [
  {
    title: 'Name',
    displayPriority: 1,
    transformResult: nameToShow,
    minWidth: 270,
  },
  {
    title: 'Occurred on',
    attributeName: 'data.occurred_on_RFC3339',
    displayPriority: 1,
    transformValue: value => formatDate(value, operatorTimezone),
    minWidth: 150,
  },
  {
    title: 'Created at',
    attributeName: 'meta.created_at_RFC3339',
    displayPriority: 1,
    transformValue: value => formatDate(value, operatorTimezone),
    minWidth: 150,
  },
  {
    title: 'Badge serial number',
    attributeName: 'data.badge.serial_number',
    displayPriority: 2,
    transformValue: sentenceCase,
    minWidth: 150,
  },
  {
    title: 'Badge technology',
    attributeName: 'data.badge.technology',
    displayPriority: 2,
    transformValue: sentenceCase,
    minWidth: 150,
  },
  {
    title: 'Booking ID',
    attributeName: 'data.booking.id',
    displayPriority: 3,
  },
  {
    title: 'Device ID',
    attributeName: 'data.device.id',
    displayPriority: 3,
    transformValue: sentenceCase,
  },
  {
    title: 'Phone',
    attributeName: 'data.device.invers.phone',
    displayPriority: 3,
    transformValue: sentenceCase,
  },
  {
    title: 'Firmware',
    attributeName: 'data.device.invers.firmware',
    displayPriority: 4,
    transformValue: sentenceCase,
  },
  {
    title: 'QNR',
    attributeName: 'data.device.invers.qnr',
    displayPriority: 4,
    transformValue: sentenceCase,
  },
  {
    title: 'Vehicle ID',
    attributeName: 'data.vehicle.id',
    displayPriority: 4,
  },
];
