import get from 'lodash/get';
import capitalize from 'lodash/capitalize';

import { RECORD_TYPES } from '@emobg/motion-ui';
import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  TIME_ZONE,
  isFalsyString,
  reformatDateTime,
  sentenceCase,
} from '@emobg/web-utils';
import carsharingRoutes from '@domains/Carsharing/router/CarsharingRouterMap';
import fleetRoutes from '@domains/Carsharing/router/FleetRouterMap';
import casesServicesRoutes from '@domains/CasesServices/router/CasesServicesRouterMap';
import { casesStatusColors } from '@domains/CasesServices/const/status';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import crm from '@domains/CRM/router/CRMRouterMap';
import { PermissionLink } from '@/components';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

export function contentCells({ operatorTimezone }) {
  return [
    {
      attributeName: 'id',
      title: 'Id',
      displayPriority: 1,
      minWidth: 120,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: {
          name: casesServicesRoutes.caseEvents.detail,
          params: {
            caseEventUuid: result.uuid,
          },
        },
        linkPermissions: [CRM_PERMISSIONS.viewCrmCases],
        text: `#${result.id}`,
      }),
    },
    {
      attributeName: 'case_type_internal_name',
      title: 'Case type',
      displayPriority: 1,
      minWidth: 260,
      transformValue: sentenceCase,
    },
    {
      attributeName: 'created_at',
      title: 'Creation date',
      displayPriority: 1,
      minWidth: 140,
      transformValue: value => reformatDateTime(
        value,
        DATE_FORMAT.defaultExtended,
        operatorTimezone,
      ),
    },
    {
      attributeName: 'reporting_user_id',
      title: 'Created by',
      displayPriority: 1,
      minWidth: 120,
      transformValue: result => (result && result.reporting_user_id === 1 ? 'System' : 'User'),
    },
    {
      attributeName: 'priority',
      title: 'Priority',
      displayPriority: 1,
      minWidth: 90,
      transformValue: sentenceCase,
    },
    {
      attributeName: 'status',
      title: 'Status',
      displayPriority: 1,
      minWidth: 120,
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: ({ status }) => ({
        text: sentenceCase(status),
        color: casesStatusColors[status],
      }),
    },
    {
      attributeName: 'assignee_user_id',
      title: 'Assignee',
      displayPriority: 2,
      minWidth: 180,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: result.assignee_user_uuid !== 'null' ? {
          name: crm.users.detail.index,
          params: {
            userUuid: result.assignee_user_uuid,
          },
        } : null,
        linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
        text: result.assignee_user_name !== 'null' ? result.assignee_user_name : FALLBACK_MESSAGE.dash,
      }),
    },
    {
      attributeName: 'user_id',
      title: 'User',
      displayPriority: 2,
      minWidth: 180,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: result.user_uuid !== 'null' ? {
          name: crm.users.detail.cases,
          params: {
            userUuid: result.user_uuid,
          },
        } : null,
        linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
        text: result.user_name !== 'null' ? result.user_name : FALLBACK_MESSAGE.dash,
      }),
    },
    {
      attributeName: 'booking_uuid',
      title: 'Booking Id',
      displayPriority: 2,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: !isFalsyString(result.booking_uuid) ? {
          name: carsharingRoutes.bookings.detail.index,
          params: {
            bookingUuid: result.booking_uuid,
          },
        } : null,
        linkPermissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
        text: result.booking_fk ? `#${result.booking_fk}` : FALLBACK_MESSAGE.dash,
      }),
      minWidth: 70,
    },
    {
      attributeName: 'vehicle_fk',
      title: 'Vehicle Id',
      displayPriority: 2,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: !isFalsyString(result.vehicle_uuid) ? {
          name: fleetRoutes.vehicles.detail.details,
          params: {
            vehicle_uuid: result.vehicle_uuid,
          },
        } : null,
        linkPermissions: [CARSHARING_PERMISSIONS.viewCarsharingVehicles],
        text: !isFalsyString(get(result, 'vehicle_uuid', 'null')) ? `${get(result, 'vehicle_license_plate')}` : FALLBACK_MESSAGE.dash,
      }),
      minWidth: 90,
    },
    {
      attributeName: 'main_entity',
      title: 'Main entity',
      displayPriority: 0,
      minWidth: 70,
      transformValue: sentenceCase,
    },
    {
      attributeName: 'city_name',
      title: 'City',
      displayPriority: 0,
      minWidth: 70,
      transformValue: sentenceCase,
    },
    {
      attributeName: 'reporting_user',
      title: 'Reporting user',
      displayPriority: 0,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: result.reporting_user_uuid !== 'null' ? {
          name: crm.users.detail.index,
          params: {
            userUuid: result.reporting_user_uuid,
          },
        } : null,
        linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
        text: result.reporting_user_name !== 'null' ? result.reporting_user_name : FALLBACK_MESSAGE.dash,
      }),
    },
    {
      attributeName: 'external_tickets',
      title: 'Zendesk',
      displayPriority: 0,
      minWidth: 60,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        href: !isFalsyString(get(result, 'external_tickets.zendesk.id', 'null')) ? get(result, 'external_tickets.zendesk.link') : null,
        linkPermissions: [],
        text: !isFalsyString(get(result, 'external_tickets.zendesk.id', 'null')) ? `${get(result, 'external_tickets.zendesk.id')}` : FALLBACK_MESSAGE.dash,
      }),
    }];
}

export function caseEventsFacets() {
  return [
    {
      type: 'refinementList',
      toggled: true,
      props: {
        toggled: true,
        title: 'City',
        attributeName: 'city_name',
      },
    },
    {
      type: 'rangeDateTimePicker',
      props: {
        toggled: true,
        title: 'Created at',
        attributeName: 'created_at_ts',
        timezone: this.operatorTimezone || TIME_ZONE.default,
        from: null,
        labels: {
          removeButton: 'Clear dates',
        },
      },
    },
    {
      type: 'refinementList',
      props: {
        toggled: true,
        title: 'Main entity',
        attributeName: 'main_entity',
        transformValue: value => capitalize(value),
      },
    },
    {
      type: 'refinementList',
      toggled: true,
      props: {
        toggled: true,
        title: 'Case type',
        attributeName: 'case_type_internal_name',
        transformValue: value => capitalize(value).replace(/_/g, ' '),
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Status',
        toggled: true,
        attributeName: 'status',
        transformValue: sentenceCase,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Entity modified',
        attributeName: 'entity_modified',
        transformValue: this.caseEntityModified,
        toggled: true,
      },
    },
    {
      type: 'refinementOptions',
      props: {
        toggled: true,
        title: 'Created by',
        attributeName: 'reporting_user_id',
        showTooltip: true,
        options: [
          {
            label: 'System',
            value: '1',
          },
          {
            label: 'User',
            value: '-1',
          },
        ],
      },
    },
    {
      type: 'refinementAutocomplete',
      props: {
        toggled: true,
        title: 'Assignee',
        attributeName: 'assignee_user_name',
      },
    },
    {
      type: 'refinementList',
      props: {
        toggled: true,
        title: 'Priority',
        attributeName: 'priority',
        transformValue: sentenceCase,
      },
    },
  ];
}
