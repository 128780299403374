var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "DetailsCommentsView",
      attrs: { "data-test-id": "details_comments-view" },
    },
    [
      _c("CommentsList", {
        attrs: {
          comments: _vm.comments,
          "is-loading": _vm.commentsStatus.LOADING,
          "entity-uuid": _vm.booking.uuid,
          callback: _vm.getCommentsByUuid,
          entity: "booking",
          "data-test-id": "list",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }