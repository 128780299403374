<script>
import isArray from 'lodash/isArray';
import size from 'lodash/size';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { BOOKING_DETAIL_SCOPES } from '../store/BookingDetailsModule';
import FraudList from './components/FraudList';
import FraudFormComponent from './components/FraudFormComponent';
import DeleteFraudFormComponent from './components/DeleteFraudFormComponent';

export default {
  name: 'BookingDetailsFraudsView',
  components: {
    DeleteFraudFormComponent,
    FraudFormComponent,
    FraudList,
  },
  data() {
    return {
      isModalVisible: false,
      isDeleteModalVisible: false,
      frauds: null,
      fraudSelected: null,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.bookings.details, {
      booking: state => state.data,
      fraud: state => (isArray(state[BOOKING_DETAIL_SCOPES.fraudCard].data)
        ? null
        : state[BOOKING_DETAIL_SCOPES.fraudCard].data),
      isFraudLoading: state => state[BOOKING_DETAIL_SCOPES.fraudCard].STATUS.LOADING,
    }),
  },
  async created() {
    await this.getBookingFraud(this.booking.uuid);
  },
  methods: {
    size,
    ...mapActions(DOMAINS_MODEL.carsharing.bookings.details, [
      'getBooking',
      'getBookingFraud',
    ]),
    setEditedFraud(fraud) {
      this.fraudSelected = fraud;
      this.isModalVisible = true;
    },
    setDeletedFraud(fraud) {
      this.fraudSelected = fraud;
      this.isDeleteModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.isDeleteModalVisible = false;
      this.fraudSelected = null;
    },
    async onSuccessForm(isDeleteForm = false) {
      const requests = [this.getBookingFraud(this.booking.uuid)];

      if (isDeleteForm || !this.fraud) {
        requests.push(this.getBooking(this.booking.uuid));
      }

      await Promise.all(requests);
    },
  },
};
</script>

<template>
  <div
    class="BookingDetailsFraudView position-relative"
    data-test-id="frauds-view"
  >
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h1>
        Fraud
      </h1>
      <ui-button
        :disabled="fraud || isFraudLoading"
        class="wmin-initial"
        @clickbutton="isModalVisible = true"
      >
        Report fraud
      </ui-button>
    </div>
    <ui-card
      class="mb-3"
      data-test-id="card"
    >
      <div>
        <ui-loader
          v-if="isFraudLoading"
          label="Loading fraud..."
        />
        <template v-if="!isFraudLoading">
          <span
            v-if="!fraud"
            class="px-1 py-2"
          >
            No fraud reported on this booking
          </span>
          <FraudList
            v-else
            :frauds="fraud"
            @edit:fraud="setEditedFraud"
            @delete:fraud="setDeletedFraud"
          />
        </template>
      </div>
    </ui-card>
    <FraudFormComponent
      v-if="isModalVisible"
      :fraud="fraudSelected"
      :on-success="onSuccessForm"
      @closeModal="closeModal"
    />
    <DeleteFraudFormComponent
      v-if="isDeleteModalVisible"
      :fraud-uuid="fraudSelected.uuid"
      :on-success="onSuccessForm"
      @closeModal="closeModal"
    />
  </div>
</template>
