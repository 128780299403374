import { COLORS, GRAYSCALE } from '@emobg/vue-base';

export const casesStatus = {
  open: 'open',
  closed: 'closed',
  inProgress: 'in progress',
  blocked: 'blocked',
};

export const casesStatusColors = {
  [casesStatus.open]: COLORS.danger,
  [casesStatus.closed]: GRAYSCALE.ground,
  [casesStatus.inProgress]: COLORS.warning,
  [casesStatus.blocked]: COLORS.moderate,
};
