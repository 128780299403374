var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "BookingDetailsHeader",
      attrs: { "data-test-id": "booking_details-header" },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "OverviewCollapse",
              {
                attrs: { id: "bookingOverview" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex flex-wrap align-items-center",
                          },
                          [
                            _c(
                              "h2",
                              {
                                staticClass: "emobg-font-weight-semibold mr-3",
                              },
                              [
                                _vm._v(
                                  " Booking #" + _vm._s(_vm.booking.id) + " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  "ui-badge",
                                  {
                                    staticClass: "mr-2",
                                    attrs: {
                                      color: _vm.GRAYSCALE.ground,
                                      "data-test-id": "system_status-badge",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                      },
                                      [
                                        _c("ui-icon", {
                                          staticClass: "mr-2",
                                          attrs: {
                                            icon: _vm.ICONS.systemStatus,
                                            size: _vm.ICONS_SIZES.xSmall,
                                          },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatNil(
                                                _vm.sentenceCase(
                                                  _vm.booking.systemStatus
                                                )
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "ui-badge",
                                  {
                                    staticClass: "mr-2",
                                    attrs: {
                                      color: _vm.GRAYSCALE.ground,
                                      "data-test-id": "vehicle_status-badge",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                      },
                                      [
                                        _c("ui-icon", {
                                          staticClass: "mr-2",
                                          attrs: {
                                            icon: _vm.ICONS.vehicleStatus,
                                            size: _vm.ICONS_SIZES.xSmall,
                                          },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.booking.isUnavailability
                                                ? _vm.FALLBACK_MESSAGE
                                                    .notAvailable
                                                : _vm.formatNil(
                                                    _vm.sentenceCase(
                                                      _vm.booking.vehicleStatus
                                                    )
                                                  )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm.isNonConnected
                                  ? _c(
                                      "ui-badge",
                                      {
                                        staticClass: "mr-2",
                                        attrs: {
                                          color: _vm.COLORS.warning,
                                          square: "",
                                          "data-test-id": "non_connected-badge",
                                        },
                                      },
                                      [_vm._v(" Non-connected ")]
                                    )
                                  : _vm._e(),
                                _vm.get(_vm.booking, "isAutoCancelled")
                                  ? _c(
                                      "ui-badge",
                                      {
                                        attrs: {
                                          color: _vm.COLORS.warning,
                                          square: true,
                                          "data-test-id":
                                            "auto_cancelled-badge",
                                        },
                                      },
                                      [_vm._v(" Auto-cancelled ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "actions",
                    fn: function () {
                      return [
                        !_vm.ENDED_USAGE_STATUS.includes(
                          _vm.get(_vm.booking, "vehicleStatus")
                        )
                          ? _c(
                              "ui-button",
                              {
                                staticClass: "ml-3",
                                attrs: {
                                  "data-test-id": "booking_details-edit-button",
                                },
                                on: { clickbutton: _vm.openBookingSetup },
                              },
                              [_vm._v(" Edit booking ")]
                            )
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c(
                  "div",
                  { staticClass: "pt-4" },
                  [
                    _vm.get(_vm.booking, "hasFraud")
                      ? _c(
                          "ui-alert",
                          {
                            staticClass: "pb-4 d-block w-100",
                            attrs: {
                              color: _vm.COLORS.danger,
                              icon: _vm.ICONS.alertFull,
                              "data-test-id": "fraud-alert",
                            },
                          },
                          [_vm._v(" Fraud reported on this booking ")]
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-lg-3" },
                        [
                          _c(
                            "ContentCellComponent",
                            {
                              staticClass: "mb-4",
                              attrs: { label: "Operator", "is-on-header": "" },
                            },
                            [
                              _c("p", { staticClass: "mb-1" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.get(
                                        _vm.booking,
                                        "operator.name",
                                        _vm.FALLBACK_MESSAGE.dash
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c(
                                "span",
                                { staticClass: "emobg-color-ink-light" },
                                [
                                  _vm._v(
                                    "City: " +
                                      _vm._s(
                                        _vm.get(
                                          _vm.booking,
                                          "location.city",
                                          _vm.FALLBACK_MESSAGE.dash
                                        )
                                      )
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "ContentCellComponent",
                            {
                              staticClass: "mb-4",
                              attrs: {
                                label: "Booking type",
                                "is-on-header": "",
                              },
                            },
                            [
                              _c("BookingTypeBadge", {
                                attrs: {
                                  type: _vm.booking.isUnavailability
                                    ? "unavailability"
                                    : _vm.booking.type,
                                  "is-automatic": _vm.get(
                                    _vm.booking,
                                    "isAutomatic",
                                    false
                                  ),
                                },
                              }),
                              _vm.booking.type ===
                                _vm.BOOKING_TYPES.intervention &&
                              _vm.get(_vm.booking, "interventionType.name")
                                ? _c(
                                    "p",
                                    {
                                      staticClass: "mt-1 emobg-color-ink-light",
                                    },
                                    [
                                      _vm._v(
                                        " Type: " +
                                          _vm._s(
                                            _vm.booking.interventionType.name
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("ContentCellComponent", {
                            staticClass: "mb-4",
                            attrs: {
                              value: _vm.formatBookingDate(
                                _vm.booking.createdAt,
                                _vm.DATE_FORMAT.defaultExtendedSeconds,
                                _vm.operatorTimezone
                              ),
                              label: "Creation date",
                              "is-on-header": "",
                            },
                          }),
                          _c(
                            "ContentCellComponent",
                            {
                              staticClass: "mb-4",
                              attrs: {
                                label: "Start date",
                                "is-on-header": "",
                              },
                            },
                            [
                              _c("p", { staticClass: "mb-1" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatBookingDate(
                                        _vm.booking.start,
                                        _vm.DATE_FORMAT.defaultExtendedSeconds,
                                        _vm.operatorTimezone
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _vm.booking.realStart
                                ? _c(
                                    "span",
                                    { staticClass: "emobg-color-ink-light" },
                                    [
                                      _vm._v(
                                        " Real start: " +
                                          _vm._s(
                                            _vm.formatBookingDate(
                                              _vm.booking.realStart,
                                              _vm.DATE_FORMAT
                                                .defaultExtendedSeconds,
                                              _vm.operatorTimezone
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "ContentCellComponent",
                            {
                              staticClass: "mb-4",
                              attrs: { label: "End date", "is-on-header": "" },
                            },
                            [
                              _c("p", { staticClass: "mb-1" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatBookingDate(
                                        _vm.booking.end,
                                        _vm.DATE_FORMAT.defaultExtendedSeconds,
                                        _vm.operatorTimezone
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _vm.booking.realEnd
                                ? _c(
                                    "span",
                                    { staticClass: "emobg-color-ink-light" },
                                    [
                                      _vm._v(
                                        " Real end: " +
                                          _vm._s(
                                            _vm.formatBookingDate(
                                              _vm.booking.realEnd,
                                              _vm.DATE_FORMAT
                                                .defaultExtendedSeconds,
                                              _vm.operatorTimezone
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm.booking.mileage
                            ? _c("ContentCellComponent", {
                                staticClass: "mb-4",
                                attrs: {
                                  value: _vm.booking.mileage
                                    ? `${_vm.booking.mileage} ${_vm.mileageUnit}`
                                    : _vm.FALLBACK_MESSAGE.dash,
                                  label: "Mileage used",
                                  "is-on-header": "",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-lg-3" },
                        [
                          _c(
                            "ContentCellComponent",
                            {
                              staticClass: "mb-4",
                              attrs: { label: "User", "is-on-header": "" },
                            },
                            [
                              _vm.get(_vm.booking, "user.firstTimeBooking")
                                ? _c(
                                    "div",
                                    { staticClass: "mb-1" },
                                    [
                                      _c("ui-badge", {
                                        attrs: {
                                          color: _vm.COLORS.warning,
                                          text: "First time booking",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isNilString(
                                _vm.get(_vm.booking, "user.name")
                              ) || _vm.isNil(_vm.get(_vm.booking, "user.id"))
                                ? _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.FALLBACK_MESSAGE.dash) +
                                        " "
                                    ),
                                  ])
                                : _c(
                                    "PermissionLink",
                                    {
                                      staticClass: "emobg-font-weight-semibold",
                                      attrs: {
                                        "link-permissions": [
                                          _vm.CRM_PERMISSIONS.viewCrmUsers,
                                        ],
                                        to: {
                                          name: _vm.crm.users.detail.bookings,
                                          params: {
                                            userUuid: _vm.booking.user.uuid,
                                          },
                                        },
                                        "data-test-id":
                                          "booking_details-user-link",
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.sentenceCase(
                                              _vm.get(_vm.booking, "user.name")
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                              _c(
                                "p",
                                { staticClass: "mt-1" },
                                [
                                  _vm.get(_vm.booking, "user.email")
                                    ? _c(
                                        "CopyToClipboard",
                                        {
                                          attrs: {
                                            "data-test-id":
                                              "booking_details-copy-email-button",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.booking.user.email) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.FALLBACK_MESSAGE.dash) +
                                            " "
                                        ),
                                      ],
                                ],
                                2
                              ),
                              _c("p", { staticClass: "mt-1" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.get(
                                        _vm.booking,
                                        "user.phone",
                                        _vm.FALLBACK_MESSAGE.dash
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "ContentCellComponent",
                            {
                              staticClass: "mb-4",
                              attrs: {
                                label: "Booking profile",
                                "is-on-header": "",
                              },
                            },
                            [
                              _c("ui-badge", {
                                attrs: {
                                  color: _vm.GRAYSCALE.ground,
                                  text: _vm.sentenceCase(
                                    _vm.booking.userProfile.type
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                          _c("ContentCellComponent", {
                            staticClass: "mb-4",
                            attrs: {
                              value: _vm.sentenceCase(
                                _vm.booking.insuranceType
                              ),
                              label: "Insurance type",
                              "is-on-header": "",
                            },
                          }),
                          !_vm.isNonConnected
                            ? _c(
                                "ContentCellComponent",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: "Alerts",
                                    "is-on-header": "",
                                  },
                                },
                                [
                                  _c("AlertsTemplate", {
                                    attrs: { alerts: _vm.booking.alerts },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-lg-3" },
                        [
                          _c(
                            "ContentCellComponent",
                            {
                              staticClass: "mb-4",
                              attrs: { label: "Vehicle", "is-on-header": "" },
                            },
                            [
                              _c(
                                "PermissionLink",
                                {
                                  staticClass: "emobg-font-weight-semibold",
                                  attrs: {
                                    "link-permissions": [
                                      _vm.CARSHARING_PERMISSIONS
                                        .viewCarsharingVehicles,
                                    ],
                                    to: {
                                      name: _vm.fleet.vehicles.detail.details,
                                      params: {
                                        vehicle_uuid: _vm.booking.vehicle.uuid,
                                      },
                                    },
                                    "data-test-id":
                                      "booking_details-vehicle-link",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatNil(
                                          _vm.booking.vehicle.licensePlate
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("p", { staticClass: "mb-1" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.booking.vehicle.brand) +
                                    " " +
                                    _vm._s(_vm.booking.vehicle.model) +
                                    " " +
                                    _vm._s(
                                      _vm.booking.vehicle.color
                                        ? `(${_vm.booking.vehicle.color})`
                                        : _vm.FALLBACK_MESSAGE.dash
                                    ) +
                                    " "
                                ),
                              ]),
                              _c(
                                "p",
                                { staticClass: "emobg-color-ink-light" },
                                [
                                  _vm._v(
                                    " Category: " +
                                      _vm._s(
                                        _vm.booking.vehicle.category ||
                                          _vm.FALLBACK_MESSAGE.dash
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          !_vm.isNonConnected
                            ? _c(
                                "ContentCellComponent",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: "Connectivity",
                                    "is-on-header": "",
                                  },
                                },
                                [
                                  _c("ConnectivityStatusComponent", {
                                    staticClass: "my-2 emobg-body-2",
                                    attrs: {
                                      status: _vm.get(
                                        _vm.booking,
                                        "vehicleTelemetry.connectivityStatus"
                                      ),
                                      "show-tooltip": "",
                                    },
                                  }),
                                  _vm.get(
                                    _vm.booking,
                                    "vehicleTelemetry.connectivityTimestamp"
                                  )
                                    ? _c("p", { staticClass: "emobg-body-1" }, [
                                        _vm._v(
                                          " Updated: " +
                                            _vm._s(
                                              _vm.formatUtc(
                                                _vm.booking.vehicleTelemetry
                                                  .connectivityTimestamp,
                                                _vm.DATE_FORMAT.defaultExtended,
                                                _vm.operatorTimezone
                                              )
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.isNonConnected
                            ? _c(
                                "ContentCellComponent",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: _vm.isElectric
                                      ? "Traction battery"
                                      : "Fuel level",
                                    "is-on-header": "",
                                  },
                                },
                                [
                                  _vm.isElectric
                                    ? _c("BatteryLevel", {
                                        attrs: {
                                          "battery-level": _vm.get(
                                            _vm.booking,
                                            "vehicleTelemetry.fuelLevel"
                                          ),
                                          "danger-level": _vm.get(
                                            _vm.booking,
                                            "vehicleTelemetry.fuelDangerLevel",
                                            0
                                          ),
                                          "icon-size": _vm.ICONS_SIZES.xLarge,
                                        },
                                      })
                                    : [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center",
                                          },
                                          [
                                            _c("ui-icon", {
                                              staticClass: "mr-2",
                                              attrs: {
                                                icon: _vm.ICONS.fuelDispenser,
                                                size: _vm.SIZES.medium,
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "emobg-body-2" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.get(
                                                        _vm.booking,
                                                        "vehicleTelemetry.fuelLevel"
                                                      )
                                                        ? `${_vm.get(
                                                            _vm.booking,
                                                            "vehicleTelemetry.fuelLevel"
                                                          )}%`
                                                        : _vm.FALLBACK_MESSAGE
                                                            .noData
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                ],
                                2
                              )
                            : _vm._e(),
                          !_vm.isNonConnected
                            ? _c(
                                "ContentCellComponent",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: "Battery level",
                                    "is-on-header": "",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center mb-1",
                                    },
                                    [
                                      _c("ui-icon", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          icon: _vm.ICONS.carBattery,
                                          size: _vm.SIZES.medium,
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "emobg-body-2" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.get(
                                                  _vm.booking,
                                                  "vehicleTelemetry.batteryLevel"
                                                )
                                                  ? `${_vm.get(
                                                      _vm.booking,
                                                      "vehicleTelemetry.batteryLevel"
                                                    )}V`
                                                  : _vm.FALLBACK_MESSAGE.noData
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.get(
                                    _vm.booking,
                                    "vehicleTelemetry.batteryLevel"
                                  )
                                    ? _c(
                                        "span",
                                        { staticClass: "emobg-body-1" },
                                        [
                                          _vm._v(
                                            " Updated: " +
                                              _vm._s(
                                                _vm.formatUtc(
                                                  _vm.get(
                                                    _vm.booking,
                                                    "vehicleTelemetry.batteryLevelTimestamp"
                                                  ),
                                                  _vm.DATE_FORMAT
                                                    .defaultExtended,
                                                  _vm.operatorTimezone
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-lg-3" },
                        [
                          _c(
                            "ContentCellComponent",
                            {
                              staticClass: "mb-4",
                              attrs: {
                                label: _vm.booking.isOneWay
                                  ? "Pickup location"
                                  : "Location",
                                "is-on-header": "",
                              },
                            },
                            [
                              _c(
                                "PermissionLink",
                                {
                                  staticClass: "emobg-font-weight-semibold",
                                  attrs: {
                                    "link-permissions": [
                                      _vm.CARSHARING_PERMISSIONS
                                        .viewCarsharingLocations,
                                    ],
                                    to: {
                                      name: _vm.fleetRoutes.locations.detail
                                        .index,
                                      params: {
                                        locationUuid: _vm.booking.location.uuid,
                                      },
                                    },
                                    "data-test-id":
                                      "booking_details-location-link",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.get(_vm.booking, "location.name")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.get(
                                        _vm.booking,
                                        "location.neighborhood"
                                      )
                                        ? `${_vm.booking.location.neighborhood},`
                                        : ""
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm.get(_vm.booking, "location.city")
                                    ) +
                                    " "
                                ),
                              ]),
                              _c(
                                "p",
                                { staticClass: "emobg-color-ink-light" },
                                [
                                  _vm._v(
                                    " Location type: " +
                                      _vm._s(
                                        _vm.get(
                                          _vm.booking,
                                          "location.type",
                                          _vm.FALLBACK_MESSAGE.dash
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _vm.get(_vm.booking, "location.address")
                                ? _c(
                                    "p",
                                    { staticClass: "emobg-color-ink-light" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.booking.location.address) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.booking.isOneWay && _vm.booking.returnLocation
                            ? _c(
                                "ContentCellComponent",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    label: "Return location",
                                    "is-on-header": "",
                                  },
                                },
                                [
                                  _c(
                                    "PermissionLink",
                                    {
                                      staticClass: "emobg-font-weight-semibold",
                                      attrs: {
                                        "link-permissions": [
                                          _vm.CARSHARING_PERMISSIONS
                                            .viewCarsharingLocations,
                                        ],
                                        to: {
                                          name: _vm.fleetRoutes.locations.detail
                                            .index,
                                          params: {
                                            locationUuid:
                                              _vm.booking.returnLocation.uuid,
                                          },
                                        },
                                        "data-test-id":
                                          "booking_details-return_location-link",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.get(
                                              _vm.booking,
                                              "returnLocation.name"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.get(
                                            _vm.booking,
                                            "returnLocation.neighborhood"
                                          )
                                            ? `${_vm.booking.returnLocation.neighborhood},`
                                            : ""
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm.get(
                                            _vm.booking,
                                            "returnLocation.city"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c(
                                    "p",
                                    { staticClass: "emobg-color-ink-light" },
                                    [
                                      _vm._v(
                                        " Location type: " +
                                          _vm._s(
                                            _vm.get(
                                              _vm.booking,
                                              "returnLocation.type",
                                              _vm.FALLBACK_MESSAGE.dash
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _vm.get(_vm.booking, "returnLocation.address")
                                    ? _c(
                                        "p",
                                        {
                                          staticClass: "emobg-color-ink-light",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.booking.returnLocation
                                                  .address
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm.isModalVisible && _vm.booking
        ? _c("BookingSetup", {
            attrs: {
              success: _vm.closeBookingSetup,
              initial: _vm.setupData,
              "booking-uuid": _vm.booking.uuid,
            },
            on: {
              closeModal: _vm.closeBookingSetup,
              "modal-closed": _vm.closeBookingSetup,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }