<script>
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  DeleteButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';
import { BOOKING_DETAIL_SCOPES } from '../../store/BookingDetailsModule';

export default {
  name: 'DeleteFraudFormComponent',
  components: {
    CancelButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
    DeleteButton,
  },
  props: {
    fraudUuid: {
      type: String,
      required: true,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.bookings.details, {
      booking: state => state.data,
      deleteFraudStatus: state => state[BOOKING_DETAIL_SCOPES.fraudDelete].STATUS,
    }),
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carsharing.bookings.details, [
      'deleteBookingFraud',
    ]),
    async removeFraud() {
      await this.deleteBookingFraud(this.booking.uuid);
      this.$emit('closeModal');
      if (!this.deleteFraudStatus.ERROR) {
        this.$notify({
          message: 'Fraud deleted',
          textAction: '',
        });
        this.onSuccess(true);
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    class="DeleteFraudFormComponent"
    data-test-id="delete_fraud-modal"
    v-on="$listeners"
  >
    <template #body>
      <DeleteModalBodyComponent
        text="This cannot be undone"
        title="Delete this fraud?"
        data-test-id="body"
      />
    </template>
    <template #footer>
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('closeModal')"
      />
      <DeleteButton
        :loading="deleteFraudStatus.LOADING"
        data-test-id="delete-button"
        @click="removeFraud"
      />
    </template>
  </GenericModalComponent>
</template>
