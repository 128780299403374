var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    { attrs: { "data-test-id": "booking-management_view" } },
    [
      _c("h1", { staticClass: "mb-2" }, [_vm._v(" Booking Management ")]),
      _c(
        "ui-card",
        [
          _c(
            "MuiValidationWrapper",
            {
              ref: "form",
              on: { areAllValid: (isValid) => (_vm.isFormValid = isValid) },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-6 mb-4" },
                  [
                    _c("MuiInputText", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            isRequired: true,
                            isPattern: _vm.PATTERN_INPUT_VALIDATIONS.uuid,
                          },
                          expression:
                            "{\n              isRequired: true,\n              isPattern: PATTERN_INPUT_VALIDATIONS.uuid,\n            }",
                        },
                      ],
                      attrs: {
                        name: "bookingUuid",
                        label: "Booking uuid*",
                        placeholder: "Enter the booking uuid",
                        "data-test-id": "booking_uuid-input",
                      },
                      model: {
                        value: _vm.bookingUuid,
                        callback: function ($$v) {
                          _vm.bookingUuid = $$v
                        },
                        expression: "bookingUuid",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-6 mb-4" },
                  [
                    _c("MuiSelect", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            isRequired: true,
                          },
                          expression:
                            "{\n              isRequired: true,\n            }",
                        },
                      ],
                      staticClass: "w-100",
                      attrs: {
                        options: _vm.usageStatusOptions,
                        name: "usageStatus",
                        label: "Usage status*",
                        placeholder: "Select the usage status",
                        "data-test-id": "usage_status-select",
                      },
                      model: {
                        value: _vm.inputs.usageStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "usageStatus", $$v)
                        },
                        expression: "inputs.usageStatus",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-6 mb-4" },
                  [
                    _c("MuiSelect", {
                      staticClass: "w-100",
                      attrs: {
                        options: _vm.statusOptions,
                        name: "status",
                        label: "Status",
                        placeholder: "Select the status",
                        "data-test-id": "status-select",
                      },
                      model: {
                        value: _vm.inputs.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "status", $$v)
                        },
                        expression: "inputs.status",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-6 mb-4" },
                  [
                    _c("MuiInputText", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            isPattern:
                              _vm.PATTERN_INPUT_VALIDATIONS.wholeNumber,
                          },
                          expression:
                            "{\n              isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,\n            }",
                        },
                      ],
                      attrs: {
                        name: "kmStart",
                        label: "Km start",
                        placeholder: "Enter the km start",
                        "data-test-id": "km_start-input",
                      },
                      model: {
                        value: _vm.inputs.kmStart,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "kmStart", $$v)
                        },
                        expression: "inputs.kmStart",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-6 mb-4" },
                  [
                    _c("MuiInputText", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            isPattern:
                              _vm.PATTERN_INPUT_VALIDATIONS.wholeNumber,
                          },
                          expression:
                            "{\n              isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,\n            }",
                        },
                      ],
                      attrs: {
                        name: "kmEnd",
                        label: "Km end",
                        placeholder: "Enter the km end",
                        "data-test-id": "km_end-input",
                      },
                      model: {
                        value: _vm.inputs.kmEnd,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "kmEnd", $$v)
                        },
                        expression: "inputs.kmEnd",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-6 mb-4" },
                  [
                    _c("MuiDatePicker", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            isValidDate: _vm.DATE_INPUT_VALIDATIONS.validDate,
                          },
                          expression:
                            "{\n              isValidDate: DATE_INPUT_VALIDATIONS.validDate,\n            }",
                        },
                      ],
                      staticClass: "w-100",
                      attrs: {
                        size: _vm.SIZES.small,
                        label: "Real start",
                        placeholder: "Enter the real start date",
                        "data-test-id": "real_start-input",
                      },
                      model: {
                        value: _vm.inputs.realStart,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "realStart", $$v)
                        },
                        expression: "inputs.realStart",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-6 mb-4" },
                  [
                    _c("MuiDatePicker", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            isValidDate: _vm.DATE_INPUT_VALIDATIONS.validDate,
                          },
                          expression:
                            "{\n              isValidDate: DATE_INPUT_VALIDATIONS.validDate,\n            }",
                        },
                      ],
                      staticClass: "w-100",
                      attrs: {
                        size: _vm.SIZES.small,
                        label: "Real end",
                        placeholder: "Enter the real end date",
                        "data-test-id": "real_end-input",
                      },
                      model: {
                        value: _vm.inputs.realEnd,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "realEnd", $$v)
                        },
                        expression: "inputs.realEnd",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-6 mb-4" },
                  [
                    _c("MuiInputText", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            isPattern:
                              _vm.PATTERN_INPUT_VALIDATIONS.floatNumber,
                          },
                          expression:
                            "{\n              isPattern: PATTERN_INPUT_VALIDATIONS.floatNumber,\n            }",
                        },
                      ],
                      attrs: {
                        name: "returnGpsLat",
                        label: "Return gps lat",
                        placeholder: "Enter the return gps lat",
                        "data-test-id": "km_start-input",
                      },
                      model: {
                        value: _vm.inputs.returnGpsLat,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "returnGpsLat", $$v)
                        },
                        expression: "inputs.returnGpsLat",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-6 mb-4" },
                  [
                    _c("MuiInputText", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.input",
                          value: {
                            isPattern:
                              _vm.PATTERN_INPUT_VALIDATIONS.floatNumber,
                          },
                          expression:
                            "{\n              isPattern: PATTERN_INPUT_VALIDATIONS.floatNumber,\n            }",
                          modifiers: { input: true },
                        },
                      ],
                      attrs: {
                        name: "returnGpsLng",
                        label: "Return gps lng",
                        placeholder: "Enter the return gps lng",
                        "data-test-id": "km_start-input",
                      },
                      model: {
                        value: _vm.inputs.returnGpsLng,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "returnGpsLng", $$v)
                        },
                        expression: "inputs.returnGpsLng",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "d-flex justify-content-end" },
            [
              _c(
                "ui-button",
                {
                  attrs: { loading: _vm.managementStatus.LOADING },
                  on: { clickbutton: _vm.bookingUsageRequest },
                },
                [_vm._v(" Save ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }