var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "BookingDetailsView d-flex flex-column flex-fill",
        {
          "justify-content-center": _vm.isLoading,
        },
      ],
      attrs: { "data-test-id": "booking_details-view" },
    },
    [
      _vm.isLoading
        ? _c("ui-loader", {
            attrs: { label: "Loading booking...", "data-test-id": "loader" },
          })
        : _vm.booking
        ? _c(
            "div",
            [
              _c(
                "PageView",
                { staticClass: "emobg-background-color-white" },
                [
                  _c("BookingDetailsHeader", {
                    attrs: { "data-test-id": "header" },
                  }),
                ],
                1
              ),
              _vm.areTabsReady
                ? _c("PageTabs", {
                    attrs: {
                      tabs: _vm.tabs,
                      "has-overview": "",
                      "data-test-id": "tabs",
                    },
                  })
                : _vm._e(),
              _c(
                "PageView",
                { attrs: { "is-tab-content": "" } },
                [
                  _c(
                    "Transition",
                    { attrs: { name: "page" } },
                    [_vm.booking ? _c("RouterView") : _vm._e()],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }