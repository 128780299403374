<script>
import { MuiTable } from '@emobg/vue-base';
import { mapActions, mapGetters, mapState } from 'vuex';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { AUDIT_ENTITIES } from '@domains/Shared/constants/audit.const';

import { contentCells } from './config/contentCells';

export default {
  components: {
    MuiTable,
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.bookings.details, {
      booking: state => state.data,
    }),
    ...mapState(DOMAINS_MODEL.shared.audit, {
      bookingAuditData: state => state.data,
      isBookingAuditDataLoading: state => state.isLoading,
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'operatorTimezone',
    ]),
  },
  async created() {
    this.contentCells = contentCells.bind(this)();
    await this.getAuditData({ primaryEntity: AUDIT_ENTITIES.booking, primaryId: this.booking.id });
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.shared.audit, [
      'getAuditData',
    ]),
  },
};
</script>
<template>
  <div data-test-id="booking_details_audit-view">
    <h1 class="mb-2">
      Audits
    </h1>
    <ui-card>
      <MuiTable
        :data-set="bookingAuditData"
        :content-cells="contentCells"
        :is-loading="isBookingAuditDataLoading"
        :no-data-label="FALLBACK_MESSAGE.dash"
        data-test-id="list"
      />
    </ui-card>
  </div>
</template>
