import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import { RECORD_TYPES } from '@emobg/motion-ui';
import {
  DATE_FORMAT,
  formatUtc,
  sentenceCase,
} from '@emobg/web-utils';
import { INVOICING_PERMISSIONS } from '@domains/Invoicing/const/permissions';
import { PermissionLink } from '@/components';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

export function contentCells({ operatorTimezone }) {
  return [
    {
      title: 'Invoice',
      displayPriority: 1,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        target: '_blank',
        href: `/invoicing/invoices/${result.uuid}/summary/details`,
        linkPermissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
        text: `${result.serie}-${result.number}`,
      }),
    },
    {
      attributeName: 'status',
      title: 'Status',
      displayPriority: 1,
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: ({ status }) => ({
        text: sentenceCase(status),
        color: status === 'open' ? COLORS.warning : GRAYSCALE.ground,
      }),
    },
    {
      attributeName: 'cs_operator',
      title: 'Operator',
      displayPriority: 1,
    },
    {
      attributeName: 'date_ts',
      title: 'Date',
      displayPriority: 1,
      transformValue: value => formatUtc(value, DATE_FORMAT.defaultExtended, operatorTimezone),
    },
  ];
}
