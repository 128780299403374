import { render, staticRenderFns } from "./DeleteFraudFormComponent.vue?vue&type=template&id=548a932d&"
import script from "./DeleteFraudFormComponent.vue?vue&type=script&lang=js&"
export * from "./DeleteFraudFormComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('548a932d')) {
      api.createRecord('548a932d', component.options)
    } else {
      api.reload('548a932d', component.options)
    }
    module.hot.accept("./DeleteFraudFormComponent.vue?vue&type=template&id=548a932d&", function () {
      api.rerender('548a932d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/domains/Carsharing/Bookings/Details/Frauds/components/DeleteFraudFormComponent.vue"
export default component.exports