<script>
import get from 'lodash/get';
import { mapGetters, mapState } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { PageView } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import {
  attributesToRetrieve,
  restrictSearchableAttributes,
} from './config';
import { useBookingList } from './composables/bookingList.composable';

export default {
  name: 'BookingsView',
  components: {
    MuiAlgoliaList,
    PageView,
  },
  setup() {
    const { contentCells, facets, exportColumns } = useBookingList();
    return { contentCells, facets, exportColumns };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      isLoading: state => get(state, 'user.STATUS.LOADING'),
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.attributesToRetrieve = attributesToRetrieve;
    this.restrictSearchableAttributes = restrictSearchableAttributes;
  },
};
</script>

<template>
  <PageView
    class="BookingsView"
    data-test-id="bookings_view"
  >
    <h1 class="mb-3">
      Bookings
    </h1>
    <MuiAlgoliaList
      v-if="!isLoading"
      :export-columns="exportColumns"
      :facets="facets"
      :filters="getOperatorFilter()"
      :index="ALGOLIA_INDEXES.csBookings"
      :query-parameters="{ attributesToRetrieve, restrictSearchableAttributes }"
      :table-config="contentCells"
      class="Bookings__MuiAlgoliaList"
      is-export-enabled
      data-test-id="list"
    />
  </PageView>
</template>
