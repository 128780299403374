import get from 'lodash/get';
import replace from 'lodash/replace';
import { computed } from 'vue';
import { CURRENCY_SYMBOLS } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { GETTERS } from '@/store/modules/App/UserAccount/UserAccountModule';
import createStore from '@/store';
import { bookingListFacets, contentCells, exportColumns } from '../config';

export const useBookingList = () => {
  const store = createStore();
  const pathUserAccount = replace(DOMAINS_MODEL.app.userAccount, /\//g, '.');
  const operatorTimezone = store.getters[`${DOMAINS_MODEL.app.userAccount}/${GETTERS.operatorTimezone}`];
  const currencySymbol = get(store, `state.${pathUserAccount}.operators.configuration.currencySymbol`, CURRENCY_SYMBOLS.EUR.symbol);
  const areNonConnectedVehiclesAllowed = get(store, `getters/${DOMAINS_MODEL.app.userAccount}/areNonConnectedVehiclesAllowed`);
  return {
    facets: computed(() => bookingListFacets(
      operatorTimezone,
      areNonConnectedVehiclesAllowed,
    )),
    contentCells: computed(() => contentCells(
      operatorTimezone,
      areNonConnectedVehiclesAllowed,
    )),
    exportColumns: computed(() => exportColumns({
      operatorTimezone,
      currencySymbol,
      areNonConnectedVehiclesAllowed,
    })),
  };
};
