export const COST_TYPES_VALUES = {
  dayCost: 'day_cost',
  distance: 'distance',
  specialCharges: 'special_charges',
};

export const COST_TYPES = [
  {
    name: 'Day rental',
    value: COST_TYPES_VALUES.dayCost,
  },
  {
    name: 'Fuel cost',
    value: COST_TYPES_VALUES.distance,
  },
  {
    name: 'Special charge',
    value: COST_TYPES_VALUES.specialCharges,
  },
];

export const VAT_OPTIONS_VALUES = {
  noVat: 0,
  original: 19,
};

export const VAT_OPTIONS = [
  {
    name: `${VAT_OPTIONS_VALUES.noVat}% without VAT`,
    value: VAT_OPTIONS_VALUES.noVat,
  },
  {
    name: `${VAT_OPTIONS_VALUES.original}% Original`,
    value: VAT_OPTIONS_VALUES.original,
  },
];
