var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "BookingDetailsFraudView position-relative",
      attrs: { "data-test-id": "frauds-view" },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between align-items-center mb-2",
        },
        [
          _c("h1", [_vm._v(" Fraud ")]),
          _c(
            "ui-button",
            {
              staticClass: "wmin-initial",
              attrs: { disabled: _vm.fraud || _vm.isFraudLoading },
              on: {
                clickbutton: function ($event) {
                  _vm.isModalVisible = true
                },
              },
            },
            [_vm._v(" Report fraud ")]
          ),
        ],
        1
      ),
      _c(
        "ui-card",
        { staticClass: "mb-3", attrs: { "data-test-id": "card" } },
        [
          _c(
            "div",
            [
              _vm.isFraudLoading
                ? _c("ui-loader", { attrs: { label: "Loading fraud..." } })
                : _vm._e(),
              !_vm.isFraudLoading
                ? [
                    !_vm.fraud
                      ? _c("span", { staticClass: "px-1 py-2" }, [
                          _vm._v(" No fraud reported on this booking "),
                        ])
                      : _c("FraudList", {
                          attrs: { frauds: _vm.fraud },
                          on: {
                            "edit:fraud": _vm.setEditedFraud,
                            "delete:fraud": _vm.setDeletedFraud,
                          },
                        }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm.isModalVisible
        ? _c("FraudFormComponent", {
            attrs: {
              fraud: _vm.fraudSelected,
              "on-success": _vm.onSuccessForm,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isDeleteModalVisible
        ? _c("DeleteFraudFormComponent", {
            attrs: {
              "fraud-uuid": _vm.fraudSelected.uuid,
              "on-success": _vm.onSuccessForm,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }