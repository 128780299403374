var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "FraudList d-flex w-100" }, [
    _c("div", { staticClass: "flex-grow-1" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12 col-md-4" },
          [
            _c(
              "ContentCellComponent",
              {
                staticClass: "mb-4",
                attrs: { label: "Fraud reasons", "data-test-id": "reasons" },
              },
              _vm._l(_vm.frauds.reasons, function (reason) {
                return _c("div", { key: reason, staticClass: "mb-1" }, [
                  _vm._v(" " + _vm._s(_vm.sentenceCase(reason)) + " "),
                ])
              }),
              0
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-12 col-md-4" },
          [
            _c(
              "ContentCellComponent",
              {
                staticClass: "mb-4",
                attrs: { label: "Fraud cost", "data-test-id": "costs" },
              },
              [
                _c("div", { staticClass: "d-flex mb-1" }, [
                  _c(
                    "div",
                    { staticClass: "flex-grow-0" },
                    [
                      _vm._l(_vm.getFraudCosts.types, function (type) {
                        return _c("div", { key: type, staticClass: "mb-1" }, [
                          _vm._v(" " + _vm._s(_vm.sentenceCase(type)) + " "),
                        ])
                      }),
                      _c("div", { staticClass: "emobg-label-1" }, [
                        _vm._v(" Total Fraud cost "),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "flex-grow-0 pl-3" },
                    [
                      _vm._l(
                        _vm.getFraudCosts.costs,
                        function (cost, indexCost) {
                          return _c(
                            "div",
                            { key: indexCost, staticClass: "mb-1" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(cost) +
                                  _vm._s(_vm.currencySymbol) +
                                  " "
                              ),
                            ]
                          )
                        }
                      ),
                      _c("div", { staticClass: "emobg-label-1" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.frauds.totalCost) +
                            _vm._s(_vm.currencySymbol) +
                            " "
                        ),
                      ]),
                    ],
                    2
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-12 col-md-4" },
          [
            _c(
              "ContentCellComponent",
              {
                staticClass: "mb-4",
                attrs: {
                  label: "Reporting Date",
                  "data-test-id": "reporting-date",
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.reformatDateTime(
                        _vm.frauds.createdAt,
                        _vm.DATE_FORMAT.defaultExtended,
                        _vm.operatorTimezone
                      )
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "ContentCellComponent",
              {
                staticClass: "mb-4",
                attrs: { label: "Agent", "data-test-id": "agent" },
              },
              [
                _c(
                  "PermissionLink",
                  {
                    staticClass: "emobg-link-primary emobg-body-2",
                    attrs: {
                      "link-permissions": [_vm.CRM_PERMISSIONS.viewCrmUsers],
                      to: {
                        name: _vm.crm.users.detail.account,
                        params: {
                          userUuid: _vm.frauds.agent.userUuid,
                        },
                      },
                      target: "_blank",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.get(_vm.frauds, "agent.firstName")) +
                        " " +
                        _vm._s(_vm.get(_vm.frauds, "agent.lastName")) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("ContentCellComponent", { attrs: { label: "Comment" } }, [
              _vm._v(
                " " +
                  _vm._s(_vm.frauds.comment || _vm.FALLBACK_MESSAGE.dash) +
                  " "
              ),
            ]),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "flex-grow-0" },
      [
        _c("Actions", {
          attrs: {
            actions: _vm.actions,
            "list-align": "bottom-left",
            "data-test-id": "actions",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }