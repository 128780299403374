<script>
import { MuiAlgoliaList } from '@emobg/motion-ui';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { TIME_ZONE } from '@emobg/web-utils';
import { mapState } from 'vuex';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import {
  contentCells,
  facets,
} from './config';
export default {
  components: {
    MuiAlgoliaList,
  },
  data() {
    return {
      algoliaFilters: '',
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone || TIME_ZONE.default,
    }),
    ...mapState(DOMAINS_MODEL.carsharing.bookings.details, {
      booking: state => state.data,
    }),
  },
  created() {
    this.algoliaIndex = ALGOLIA_INDEXES.transceiverEvents;
    this.algoliaConfig = contentCells({
      operatorTimezone: this.operatorTimezone,
    });
    this.algoliaFacets = facets;
    if (this.booking) {
      this.algoliaFilters = `data.booking.id:${this.booking.uuid}`;
    }
  },
};
</script>
<template>
  <div data-test-id="booking_details_events-view">
    <h1 class="mb-2">
      Transceiver Events
    </h1>
    <MuiAlgoliaList
      v-if="algoliaIndex"
      :index="algoliaIndex"
      :filters="algoliaFilters"
      :facets="algoliaFacets"
      :table-config="algoliaConfig"
      data-test-id="list"
    />
  </div>
</template>
