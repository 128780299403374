<script>
import gt from 'lodash/gt';
import includes from 'lodash/includes';
import map from 'lodash/map';
import find from 'lodash/find';
import toString from 'lodash/toString';
import round from 'lodash/round';
import get from 'lodash/get';

import { MuiModal } from '@emobg/motion-ui/v1';

import { CancelButton } from '@/components';
import {
  COST_TYPES,
  COST_TYPES_VALUES,
  VAT_OPTIONS,
  VAT_OPTIONS_VALUES,
} from './constants';

export default {
  components: {
    CancelButton,
    MuiModal,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Function,
      default: () => {},
    },
    onSave: {
      type: Function,
      default: () => {},
    },
    costs: {
      type: Array,
      default: () => [],
    },
    isAddSpecialCharge: {
      type: Boolean,
      default: false,
    },
    isEditSpecialCharge: {
      type: Boolean,
      default: false,
    },
    settlementSpecialCharge: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      formData: {
        costPerUnit: undefined,
        comment: '',
        type: undefined,
        units: undefined,
        credit: false,
        taxPercentage: VAT_OPTIONS_VALUES.original,
      },
    };
  },
  computed: {
    isValid() {
      return this.formData.type && this.areNumbersValid;
    },
    areNumbersValid() {
      return gt(this.formData.costPerUnit, 0) && gt(this.formData.units, 0);
    },
    totalNetAmount() {
      return ((this.formData.costPerUnit * this.formData.units) || 0).toFixed(2);
    },
    totalAmount() {
      return (this.totalNetAmount * (1 + this.formData.taxPercentage / 100)).toFixed(2);
    },
    isCreditDisabled() {
      return includes([COST_TYPES_VALUES.dayCost, COST_TYPES_VALUES.distance], this.formData.type);
    },
    costTypes() {
      return map(COST_TYPES, costType => ({
        label: costType.name,
        value: costType.value,
        disabled: !!find(this.costs, { type: costType.value }),
      }));
    },
    isSpecialCharge() {
      return this.isAddSpecialCharge || this.isEditSpecialCharge;
    },
    modalTitle() {
      if (this.isAddSpecialCharge) {
        return 'Add special charge';
      }
      return this.isEditSpecialCharge
        ? 'Edit special charge'
        : 'Add cost';
    },
  },
  watch: {
    'formData.units': {
      handler(value) {
        this.formData.units = toString(round(value));
      },
    },
    'formData.taxPercentage': {
      handler(selectedVat) {
        this.selectedVat = get(find(this.vatOptions, { value: selectedVat }), 'label');
      },
    },
    isAddSpecialCharge(value) {
      this.formData.type = value ? COST_TYPES_VALUES.specialCharges : undefined;
    },
    isEditSpecialCharge(value) {
      this.formData.type = value ? COST_TYPES_VALUES.specialCharges : undefined;
    },
    settlementSpecialCharge: {
      handler(settlement) {
        const specialCharge = find(get(settlement, 'settlementLines'), { type: COST_TYPES_VALUES.specialCharges });
        if (specialCharge) {
          this.formData.costPerUnit = specialCharge.costPerUnit;
          this.formData.units = specialCharge.units;
          this.formData.taxPercentage = specialCharge.taxPercentage;
          this.formData.credit = specialCharge.credit;
          this.formData.comment = specialCharge.comment;
        }
      },
      deep: true,
    },
  },
  created() {
    this.vatOptions = map(VAT_OPTIONS, vatOption => ({ label: vatOption.name, value: vatOption.value }));
    this.formData.taxPercentage = VAT_OPTIONS_VALUES.original;
    this.modalConfig = {
      header: {
        class: 'pl-3',
        isClosable: true,
      },
    };
  },
  methods: {
    createCost() {
      this.onSave({
        ...this.formData,
        taxPercentage: this.formData.taxPercentage.toFixed(2),
        totalNetAmount: this.totalNetAmount,
        totalAmount: this.totalAmount,
      });
      this.resetFormData();
      this.close();
    },
    resetFormData() {
      this.formData = {
        costPerUnit: undefined,
        comment: '',
        type: undefined,
        units: undefined,
        credit: false,
        taxPercentage: VAT_OPTIONS_VALUES.original,
      };
    },
  },
};
</script>
<template>
  <MuiModal
    v-bind="modalConfig"
    :is-open="isOpen"
    :title="modalTitle"
    data-test-id="create_cost-modal"
    @modal-closed="close"
  >
    <div slot="body">
      <ui-select
        :value="formData.type"
        :options.prop="costTypes"
        :disabled="isSpecialCharge"
        label="Type of cost*"
        placeholder="Select"
        data-test-id="cost_type-selector"
        @selectoption="({ detail }) => (formData.type = detail)"
      />
      <div class="emobg-body-medium emobg-font-weight-semibold d-flex align-items-center mb-1 px-1">
        Type of transaction*
      </div>
      <div class="d-flex flex-column mb-3">
        <ui-radio
          :value="formData.credit"
          :option="false"
          caption="Debit"
          name="debit-input"
          @changevalue="({ detail }) => formData.credit = detail"
        />
        <ui-radio
          :value="formData.credit"
          :option="true"
          :disabled="isCreditDisabled"
          caption="Credit"
          name="credit-input"
          @changevalue="({ detail }) => formData.credit = detail"
        />
      </div>
      <ui-text-input
        :value="formData.costPerUnit"
        :type="INPUT_TYPES.number"
        label="Price per unit (€)*"
        class="mb-3"
        name="amount"
        placeholder="Enter number"
        data-test-id="amount-input"
        @changevalue="event => formData.costPerUnit = event.detail"
      />
      <ui-text-input
        :value="formData.units"
        :type="INPUT_TYPES.number"
        label="Units*"
        class="mb-3"
        name="units"
        placeholder="Enter number"
        data-test-id="units-input"
        @changevalue="event => formData.units = event.detail"
      />
      <div class="emobg-background-color-ground-lightest p-2 mb-3 emobg-border-radius-small">
        <div class="emobg-body-medium emobg-font-weight-semibold d-flex align-items-center mb-1">
          Net amount
        </div>
        <div data-test-id="net_amount-value">
          {{ totalNetAmount }} €
        </div>
      </div>
      <ui-select
        :value.prop="selectedVat"
        :options.prop="vatOptions"
        label="VAT*"
        name="cost_type"
        class="w-100 mb-3"
        placeholder="Select"
        data-test-id="vat-selector"
        @selectoption="({ detail }) => formData.taxPercentage = detail"
      />
      <div class="emobg-background-color-ground-lightest p-2 mb-3 emobg-border-radius-small">
        <div class="emobg-body-medium emobg-font-weight-semibold d-flex align-items-center mb-1">
          Total amount
        </div>
        <div data-test-id="total_amount-value">
          {{ totalAmount }} €
        </div>
      </div>
      <ui-text-area
        :value="formData.comment"
        placeholder="Add optional comment"
        label="Comment"
        name="comment"
        data-test-id="comments-input"
        @changevalue="({ detail }) => formData.comment = detail"
      />
    </div>
    <template slot="footer">
      <div class="d-flex align-content-center justify-content-end w-100 p-3">
        <CancelButton
          data-test-id="close-button"
          @click="close"
        />
        <ui-button
          data-test-id="save-button"
          :disabled="!isValid"
          @clickbutton="createCost"
        >
          {{ isEditSpecialCharge ? 'Edit cost' : 'Add cost' }}
        </ui-button>
      </div>
    </template>
  </MuiModal>
</template>
