import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import carsharingRoutes from '../../../router/CarsharingRouterMap';

export const BOOKING_TABS = [
  {
    label: {
      title: 'Events',
    },
    url: carsharingRoutes.bookings.detail.events,
    permissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
  },
  {
    label: {
      title: 'Invoices',
    },
    url: carsharingRoutes.bookings.detail.invoices,
    permissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
  },
  {
    label: {
      title: 'Audits',
    },
    url: carsharingRoutes.bookings.detail.audits,
    permissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
  },
  {
    label: {
      title: 'Cases',
    },
    url: carsharingRoutes.bookings.detail.cases,
    permissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
  },
  {
    label: {
      title: 'Comments',
    },
    url: carsharingRoutes.bookings.detail.comments,
    permissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
  },
  {
    label: {
      title: 'Additional cost',
    },
    url: carsharingRoutes.bookings.detail.additionalCosts,
    permissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
  },
  {
    label: {
      title: 'Fraud',
    },
    url: carsharingRoutes.bookings.detail.fraud,
    permissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
  },
  {
    label: {
      title: 'Vehicle interactions',
    },
    url: carsharingRoutes.bookings.detail.vehicleInteractions,
    permissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
  },
];
