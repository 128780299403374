<script>
import get from 'lodash/get';
import filter from 'lodash/filter';
import intersection from 'lodash/intersection';
import isNil from 'lodash/isNil';
import head from 'lodash/head';
import { mapActions, mapMutations, mapState } from 'vuex';
import { navigationErrorHandler } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import sharedRoutes from '@domains/Shared/router/SharedRouterMap';
import { PageTabs, PageView } from '@/components';
import { BOOKING_TABS } from './const/tabs';
import BookingDetailsHeader from './components/BookingDetailsHeader/BookingDetailsHeader';
import carsharingRoutes from '../../router/CarsharingRouterMap';

export default {
  name: 'BookingDetailsView',
  components: {
    BookingDetailsHeader,
    PageTabs,
    PageView,
  },
  beforeRouteLeave(_to, _from, next) {
    // Remove booking in the store so when we enter again it will not appear the old booking loaded
    this.setBookingDetailData(null);
    next();
  },
  props: {
    bookingUuid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      areTabsReady: false,
      tabs: [],
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.bookings.details, {
      booking: state => state.data,
      isLoaded: state => state.STATUS.LOADED,
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      userPermissions: state => get(state, 'user.data.permissions', []),
    }),
    hasAirPlus() {
      return get(this.booking, 'operator.hasAirPlus');
    },
    isNonConnected() {
      return get(this, 'booking.nonConnected');
    },
  },
  watch: {
    async bookingUuid() {
      await this.initialLoad();
    },
  },
  async created() {
    await this.initialLoad();
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carsharing.bookings.details, [
      'getBooking',
    ]),
    ...mapMutations(DOMAINS_MODEL.carsharing.bookings.details, {
      setBookingDetailData: 'setData',
    }),
    updateTabsVisibility() {
      this.areTabsReady = false;
      this.tabs = filter(BOOKING_TABS, item => {
        const matchesPermissions = intersection(this.userPermissions, item.permissions).length;
        const permissionsByRoute = {
          [carsharingRoutes.bookings.detail.additionalCosts]: matchesPermissions && this.hasAirPlus,
          [carsharingRoutes.bookings.detail.events]: matchesPermissions && !this.isNonConnected,
        };

        return !isNil(permissionsByRoute[item.url]) ? permissionsByRoute[item.url] : matchesPermissions;
      });

      if (!filter(this.tabs, item => item.url === this.$route.name).length) {
        this.$router.push({ name: get(head(this.tabs), 'url') }).catch(navigationErrorHandler);
      }

      this.areTabsReady = true;
    },
    async initialLoad() {
      this.isLoading = true;
      await this.getBooking(this.bookingUuid);

      if (!this.booking) {
        this.$router.push({ name: sharedRoutes.notFound }).catch(navigationErrorHandler);
      }

      this.updateTabsVisibility();
      this.isLoading = false;
    },
  },
};
</script>

<template>
  <div
    :class="[
      'BookingDetailsView d-flex flex-column flex-fill',
      {
        'justify-content-center': isLoading,
      }
    ]"
    data-test-id="booking_details-view"
  >
    <ui-loader
      v-if="isLoading"
      label="Loading booking..."
      data-test-id="loader"
    />
    <div v-else-if="booking">
      <PageView class="emobg-background-color-white">
        <BookingDetailsHeader data-test-id="header" />
      </PageView>
      <PageTabs
        v-if="areTabsReady"
        :tabs="tabs"
        has-overview
        data-test-id="tabs"
      />
      <PageView is-tab-content>
        <Transition name="page">
          <RouterView v-if="booking" />
        </Transition>
      </PageView>
    </div>
  </div>
</template>
