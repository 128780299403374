var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ContentCellComponent",
    { staticClass: "FraudCostFormComponent", attrs: { label: "Fraud cost" } },
    [
      _c(
        "MuiValidationWrapper",
        {
          staticClass: "d-flex w-100",
          on: { areAllValid: (isValid) => (_vm.isFormValid = isValid) },
        },
        [
          _c(
            "div",
            { staticClass: "flex-fill-0" },
            [
              _c("MuiSelect", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      isRequired: true,
                    },
                    expression: "{\n          isRequired: true,\n        }",
                  },
                ],
                ref: "typeSelector",
                staticClass: "w-100",
                attrs: {
                  options: _vm.fraudCostTypesNormalized,
                  placeholder: "Select cost",
                  name: "type",
                },
                model: {
                  value: _vm.inputs.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.inputs, "type", $$v)
                  },
                  expression: "inputs.type",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex-fill-0" },
            [
              _c("MuiInputText", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      isRequired: true,
                      isPattern: _vm.PATTERN_INPUT_VALIDATIONS.decimalNumber,
                      isBiggerThan0Validation: _vm.isBiggerThan0Validation,
                    },
                    expression:
                      "{\n          isRequired: true,\n          isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,\n          isBiggerThan0Validation,\n        }",
                  },
                ],
                ref: "costInput",
                staticClass: "ml-1",
                attrs: {
                  "model-value": _vm.inputs.cost,
                  placeholder: "Enter number value (e.g. 10,30)",
                  name: "cost",
                },
                on: {
                  blur: (newCost) =>
                    (_vm.inputs.cost = _vm.toDecimalFormat(newCost)),
                },
              }),
            ],
            1
          ),
          _c(
            "ui-button",
            {
              staticClass: "FraudCostFormComponent__add ml-1",
              attrs: { disabled: !_vm.isFormValid, face: _vm.FACES.outline },
              on: { clickbutton: _vm.add },
            },
            [_vm._v(" Add ")]
          ),
        ],
        1
      ),
      _vm.costs.length
        ? _c(
            "div",
            { staticClass: "d-flex flex-column w-100 mt-3" },
            _vm._l(_vm.costs, function (costLine, index) {
              return _c(
                "div",
                {
                  key: costLine.type,
                  staticClass:
                    "d-flex align-items-center py-2 w-100 emobg-border-bottom-1 emobg-border-color-ground-lighter",
                },
                [
                  _c("div", { staticClass: "flex-fill-0" }, [
                    _vm._v(" " + _vm._s(_vm.sentenceCase(costLine.type)) + " "),
                  ]),
                  _c("div", { staticClass: "flex-fill-0" }, [
                    _vm._v(
                      " " +
                        _vm._s(costLine.cost) +
                        _vm._s(_vm.currencySymbol) +
                        " "
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-content-center",
                      staticStyle: { flex: "0 0 58px" },
                    },
                    [
                      _c(
                        "ui-tooltip",
                        {
                          attrs: {
                            placement: _vm.PLACEMENTS.top,
                            tooltip: "Remove",
                          },
                        },
                        [
                          _c("ui-icon", {
                            staticClass: "cursor-pointer",
                            attrs: { icon: _vm.ICONS.minus },
                            on: { click: () => _vm.remove(index) },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }