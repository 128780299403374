var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-test-id": "additional_costs-view" } },
    [
      _c("h1", { staticClass: "mb-2" }, [_vm._v(" Additional costs ")]),
      _c(
        "ui-card",
        {
          staticClass: "mb-4 d-block",
          attrs: { "data-test-id": "draft-card" },
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-between align-items-center",
            },
            [
              _c("h2", { staticClass: "emobg-font-weight-semibold" }, [
                _vm._v(" Settlement draft "),
              ]),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "ui-button",
                    {
                      staticClass: "mr-3",
                      attrs: {
                        face: _vm.FACES.outline,
                        disabled: !_vm.costs.length,
                        "data-test-id": "send-button",
                      },
                      on: {
                        clickbutton: function ($event) {
                          _vm.isSendModalOpen = true
                        },
                      },
                    },
                    [
                      _vm._v(
                        " Create settlement from costs (" +
                          _vm._s(_vm.costs.length) +
                          ") "
                      ),
                    ]
                  ),
                  _c(
                    "ui-button",
                    {
                      attrs: {
                        disabled: _vm.isCreateCostDisabled,
                        "data-test-id": "create-button",
                      },
                      on: {
                        clickbutton: function ($event) {
                          _vm.isCreateModalOpen = true
                        },
                      },
                    },
                    [_vm._v(" Add cost ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c("div", {
            staticClass:
              "w-100 emobg-border-top-1 emobg-border-color-ground my-3",
          }),
          _c(
            "div",
            { staticClass: "d-flex flex-column align-items-center" },
            [
              _c("TableComponent", {
                staticClass: "w-100",
                attrs: {
                  schema: _vm.settlementsDraftSchema,
                  data: _vm.costs,
                  "empty-label": _vm.FALLBACK_MESSAGE.dash,
                  "data-test-id": "settlements_draft-table",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "ui-card",
        { attrs: { "data-test-id": "settlements-card" } },
        [
          _c("h2", { staticClass: "mb-2 mt-1 emobg-font-weight-semibold" }, [
            _vm._v(" Settlements "),
          ]),
          _c("div", {
            staticClass:
              "w-100 emobg-border-top-1 emobg-border-color-ground my-3",
          }),
          _c(
            "ui-alert",
            {
              staticClass: "pb-3 d-block w-100",
              attrs: { color: _vm.COLORS.primary, icon: _vm.ICONS.infoFull },
            },
            [
              _c("span", { staticClass: "emobg-font-weight-semibold" }, [
                _vm._v("Special charge"),
              ]),
              _vm._v(" can be managed on settlements with status "),
              _c("span", { staticClass: "emobg-font-weight-semibold" }, [
                _vm._v("Created"),
              ]),
            ]
          ),
          _c("TableComponent", {
            attrs: {
              schema: _vm.settlementsSchema,
              data: _vm.settlements,
              "row-actions": _vm.settlementsActions,
              "empty-label": _vm.FALLBACK_MESSAGE.dash,
              "data-test-id": "settlements-table",
            },
          }),
        ],
        1
      ),
      _c("CreateAdditionalCost", {
        attrs: {
          "is-open": _vm.isCreateModalOpen,
          close: () => (_vm.isCreateModalOpen = false),
          "on-save": _vm.onSaveAdditionalCostLine,
          costs: _vm.costs,
          "is-add-special-charge": _vm.isAddSpecialCharge,
          "is-edit-special-charge": _vm.isEditSpecialCharge,
          "settlement-special-charge": _vm.specialChargeSettlement,
        },
      }),
      _c(
        "MuiModal",
        {
          attrs: { "data-test-id": "confirm-modal", "backdrop-dismiss": "" },
          model: {
            value: _vm.isSendModalOpen,
            callback: function ($$v) {
              _vm.isSendModalOpen = $$v
            },
            expression: "isSendModalOpen",
          },
        },
        [
          _c("template", { slot: "body" }, [
            _c("div", { staticClass: "py-3" }, [
              _c("h2", { staticClass: "mb-3 emobg-font-weight-semibold" }, [
                _vm._v(" Create a settlement from these costs? "),
              ]),
              _c("div", { staticClass: "emobg-color-ink-light" }, [
                _vm._v(" This cannot be undone "),
              ]),
            ]),
          ]),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-content-center justify-content-end w-100 p-3",
              },
              [
                _c("CancelButton", {
                  attrs: { "data-test-id": "cancel-button" },
                  on: {
                    click: function ($event) {
                      _vm.isSendModalOpen = false
                    },
                  },
                }),
                _c(
                  "ui-button",
                  {
                    attrs: {
                      disabled: _vm.costsStatus.LOADING,
                      "data-test-id": "send-button",
                    },
                    on: { clickbutton: _vm.createSettlement },
                  },
                  [_vm._v(" Create ")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "MuiModal",
        {
          attrs: { "data-test-id": "refund-modal", "backdrop-dismiss": "" },
          model: {
            value: _vm.isRefundModalOpen,
            callback: function ($$v) {
              _vm.isRefundModalOpen = $$v
            },
            expression: "isRefundModalOpen",
          },
        },
        [
          _c("template", { slot: "body" }, [
            _c("div", { staticClass: "py-3" }, [
              _c("h2", { staticClass: "mb-3 emobg-font-weight-semibold" }, [
                _vm._v(" Refund this settlement? "),
              ]),
              _c("div", { staticClass: "emobg-color-ink-light" }, [
                _vm._v(" This cannot be undone "),
              ]),
            ]),
          ]),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-content-center justify-content-end w-100 p-3",
              },
              [
                _c("CancelButton", {
                  attrs: { "data-test-id": "cancel-button" },
                  on: {
                    click: function ($event) {
                      _vm.isRefundModalOpen = false
                    },
                  },
                }),
                _c(
                  "ui-button",
                  {
                    attrs: {
                      disabled: _vm.refundStatus.LOADING,
                      "data-test-id": "save-button",
                    },
                    on: { clickbutton: _vm.refundSettlement },
                  },
                  [_vm._v(" Refund ")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }