var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-test-id": "booking_details_cases-view" } },
    [
      _c(
        "div",
        { staticClass: "d-flex align-items-center mb-2" },
        [
          _c("h1", { staticClass: "flex-grow-1" }, [_vm._v(" Cases ")]),
          _c(
            "ui-button",
            {
              attrs: { "data-test-id": "create-button" },
              on: { clickbutton: _vm.goToCreateCase },
            },
            [_vm._v(" Create case ")]
          ),
        ],
        1
      ),
      _vm.algoliaIndex
        ? _c("MuiAlgoliaList", {
            attrs: {
              index: _vm.algoliaIndex,
              filters: `booking_fk:${_vm.get(_vm.booking, "id")}`,
              facets: _vm.caseEventsFacets,
              "table-config": _vm.algoliaConfig,
              "export-columns": _vm.algoliaConfig,
              "data-test-id": "list",
              "is-export-enabled": "",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }