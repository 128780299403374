var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    { staticClass: "BookingsView", attrs: { "data-test-id": "bookings_view" } },
    [
      _c("h1", { staticClass: "mb-3" }, [_vm._v(" Bookings ")]),
      !_vm.isLoading
        ? _c("MuiAlgoliaList", {
            staticClass: "Bookings__MuiAlgoliaList",
            attrs: {
              "export-columns": _vm.exportColumns,
              facets: _vm.facets,
              filters: _vm.getOperatorFilter(),
              index: _vm.ALGOLIA_INDEXES.csBookings,
              "query-parameters": {
                attributesToRetrieve: _vm.attributesToRetrieve,
                restrictSearchableAttributes: _vm.restrictSearchableAttributes,
              },
              "table-config": _vm.contentCells,
              "is-export-enabled": "",
              "data-test-id": "list",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }