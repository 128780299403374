<script>
import get from 'lodash/get';
import join from 'lodash/join';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import { mapState } from 'vuex';
import {
  DATE_FORMAT,
  reformatDateTime,
  sentenceCase,
  TIME_ZONE,
} from '@emobg/web-utils';
import crm from '@domains/CRM/router/CRMRouterMap';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  Actions,
  ContentCellComponent,
  PermissionLink,
} from '@/components';

export default {
  name: 'FraudList',
  components: {
    ContentCellComponent,
    PermissionLink,
    Actions,
  },

  props: {
    frauds: {
      type: Object,
      default: null,
      required: true,
    },
  },

  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      currencySymbol: state => state.operators.configuration.currencySymbol,
      operatorTimezone: state => state.operators.active.timezone || TIME_ZONE.default,
    }),
    getFraudCosts() {
      const types = [];
      const costs = [];
      forEach(this.frauds.costs, cost => {
        types.push(cost.type);
        costs.push(cost.cost);
      });
      return {
        types,
        costs,
      };
    },
  },

  created() {
    this.DATE_FORMAT = DATE_FORMAT;
    this.CRM_PERMISSIONS = CRM_PERMISSIONS;
    this.crm = crm;

    this.actions = [
      {
        label: 'Edit fraud',
        action: () => this.$emit('edit:fraud', this.frauds),
        labelClass: 'emobg-font-weight-semibold px-1',
      },
      {
        label: 'Delete fraud',
        action: () => this.$emit('delete:fraud', this.frauds),
        labelClass: 'emobg-color-danger emobg-font-weight-semibold px-1',
      },
    ];
  },

  methods: {
    get,
    join,
    forEach,
    map,
    sentenceCase,
    reformatDateTime,
  },
};
</script>

<template>
  <div class="FraudList d-flex w-100">
    <div class="flex-grow-1">
      <div class="row">
        <div class="col-12 col-md-4">
          <ContentCellComponent
            label="Fraud reasons"
            class="mb-4"
            data-test-id="reasons"
          >
            <div
              v-for="reason in frauds.reasons"
              :key="reason"
              class="mb-1"
            >
              {{ sentenceCase(reason) }}
            </div>
          </ContentCellComponent>
        </div>
        <div class="col-12 col-md-4">
          <ContentCellComponent
            label="Fraud cost"
            class="mb-4"
            data-test-id="costs"
          >
            <div class="d-flex mb-1">
              <div class="flex-grow-0">
                <div
                  v-for="type in getFraudCosts.types"
                  :key="type"
                  class="mb-1"
                >
                  {{ sentenceCase(type) }}
                </div>
                <div class="emobg-label-1">
                  Total Fraud cost
                </div>
              </div>
              <div class="flex-grow-0 pl-3">
                <div
                  v-for="(cost, indexCost) in getFraudCosts.costs"
                  :key="indexCost"
                  class="mb-1"
                >
                  {{ cost }}{{ currencySymbol }}
                </div>
                <div class="emobg-label-1">
                  {{ frauds.totalCost }}{{ currencySymbol }}
                </div>
              </div>
            </div>
          </ContentCellComponent>
        </div>
        <div class="col-12 col-md-4">
          <ContentCellComponent
            label="Reporting Date"
            class="mb-4"
            data-test-id="reporting-date"
          >
            {{ reformatDateTime(frauds.createdAt, DATE_FORMAT.defaultExtended, operatorTimezone) }}
          </ContentCellComponent>
          <ContentCellComponent
            label="Agent"
            class="mb-4"
            data-test-id="agent"
          >
            <PermissionLink
              :link-permissions="[CRM_PERMISSIONS.viewCrmUsers]"
              :to="{
                name: crm.users.detail.account,
                params: {
                  userUuid: frauds.agent.userUuid,
                },
              }"
              class="emobg-link-primary emobg-body-2"
              target="_blank"
            >
              {{ get(frauds, 'agent.firstName') }}
              {{ get(frauds, 'agent.lastName') }}
            </PermissionLink>
          </ContentCellComponent>
        </div>

        <div class="col-12">
          <ContentCellComponent label="Comment">
            {{ frauds.comment || FALLBACK_MESSAGE.dash }}
          </ContentCellComponent>
        </div>
      </div>
    </div>
    <div class="flex-grow-0">
      <Actions
        :actions="actions"
        list-align="bottom-left"
        data-test-id="actions"
      />
    </div>
  </div>
</template>
