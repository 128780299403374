var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiModal",
    _vm._b(
      {
        attrs: {
          "is-open": _vm.isOpen,
          title: _vm.modalTitle,
          "data-test-id": "create_cost-modal",
        },
        on: { "modal-closed": _vm.close },
      },
      "MuiModal",
      _vm.modalConfig,
      false
    ),
    [
      _c(
        "div",
        { attrs: { slot: "body" }, slot: "body" },
        [
          _c("ui-select", {
            attrs: {
              value: _vm.formData.type,
              disabled: _vm.isSpecialCharge,
              label: "Type of cost*",
              placeholder: "Select",
              "data-test-id": "cost_type-selector",
            },
            domProps: { options: _vm.costTypes },
            on: { selectoption: ({ detail }) => (_vm.formData.type = detail) },
          }),
          _c(
            "div",
            {
              staticClass:
                "emobg-body-medium emobg-font-weight-semibold d-flex align-items-center mb-1 px-1",
            },
            [_vm._v(" Type of transaction* ")]
          ),
          _c(
            "div",
            { staticClass: "d-flex flex-column mb-3" },
            [
              _c("ui-radio", {
                attrs: {
                  value: _vm.formData.credit,
                  option: false,
                  caption: "Debit",
                  name: "debit-input",
                },
                on: {
                  changevalue: ({ detail }) => (_vm.formData.credit = detail),
                },
              }),
              _c("ui-radio", {
                attrs: {
                  value: _vm.formData.credit,
                  option: true,
                  disabled: _vm.isCreditDisabled,
                  caption: "Credit",
                  name: "credit-input",
                },
                on: {
                  changevalue: ({ detail }) => (_vm.formData.credit = detail),
                },
              }),
            ],
            1
          ),
          _c("ui-text-input", {
            staticClass: "mb-3",
            attrs: {
              value: _vm.formData.costPerUnit,
              type: _vm.INPUT_TYPES.number,
              label: "Price per unit (€)*",
              name: "amount",
              placeholder: "Enter number",
              "data-test-id": "amount-input",
            },
            on: {
              changevalue: (event) => (_vm.formData.costPerUnit = event.detail),
            },
          }),
          _c("ui-text-input", {
            staticClass: "mb-3",
            attrs: {
              value: _vm.formData.units,
              type: _vm.INPUT_TYPES.number,
              label: "Units*",
              name: "units",
              placeholder: "Enter number",
              "data-test-id": "units-input",
            },
            on: { changevalue: (event) => (_vm.formData.units = event.detail) },
          }),
          _c(
            "div",
            {
              staticClass:
                "emobg-background-color-ground-lightest p-2 mb-3 emobg-border-radius-small",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "emobg-body-medium emobg-font-weight-semibold d-flex align-items-center mb-1",
                },
                [_vm._v(" Net amount ")]
              ),
              _c("div", { attrs: { "data-test-id": "net_amount-value" } }, [
                _vm._v(" " + _vm._s(_vm.totalNetAmount) + " € "),
              ]),
            ]
          ),
          _c("ui-select", {
            staticClass: "w-100 mb-3",
            attrs: {
              label: "VAT*",
              name: "cost_type",
              placeholder: "Select",
              "data-test-id": "vat-selector",
            },
            domProps: { value: _vm.selectedVat, options: _vm.vatOptions },
            on: {
              selectoption: ({ detail }) =>
                (_vm.formData.taxPercentage = detail),
            },
          }),
          _c(
            "div",
            {
              staticClass:
                "emobg-background-color-ground-lightest p-2 mb-3 emobg-border-radius-small",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "emobg-body-medium emobg-font-weight-semibold d-flex align-items-center mb-1",
                },
                [_vm._v(" Total amount ")]
              ),
              _c("div", { attrs: { "data-test-id": "total_amount-value" } }, [
                _vm._v(" " + _vm._s(_vm.totalAmount) + " € "),
              ]),
            ]
          ),
          _c("ui-text-area", {
            attrs: {
              value: _vm.formData.comment,
              placeholder: "Add optional comment",
              label: "Comment",
              name: "comment",
              "data-test-id": "comments-input",
            },
            on: {
              changevalue: ({ detail }) => (_vm.formData.comment = detail),
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex align-content-center justify-content-end w-100 p-3",
          },
          [
            _c("CancelButton", {
              attrs: { "data-test-id": "close-button" },
              on: { click: _vm.close },
            }),
            _c(
              "ui-button",
              {
                attrs: {
                  "data-test-id": "save-button",
                  disabled: !_vm.isValid,
                },
                on: { clickbutton: _vm.createCost },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.isEditSpecialCharge ? "Edit cost" : "Add cost") +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }