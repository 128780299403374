import {
  DATE_FORMAT,
  camelCaseKeys,
  formatUtc,
  sentenceCase,
} from '@emobg/web-utils';
import BookingAuditComponent from '../components/BookingAuditComponent';

export function contentCells() {
  return [
    {
      property: 'auditAction',
      title: 'Action',
      transformValue: value => sentenceCase(value.replace('booking_', '')),
    },
    {
      property: 'createdAtTs',
      title: 'Date',
      transformValue: value => formatUtc(value, DATE_FORMAT.defaultExtended, this.operatorTimezone),
    },
    {
      property: 'userName',
      title: 'User',
      width: 200,
    },
    {
      component: BookingAuditComponent,
      folded: true,
      props: result => ({
        before: camelCaseKeys(result.valueBefore),
        after: camelCaseKeys(result.valueAfter),
      }),
    },
  ];
}
