<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CommentsList } from '@/components';
import { COMMENTABLE_TYPES } from '@/components/Comments/v1/constants/comments';

export default {
  name: 'DetailsCommentsView',
  components: {
    CommentsList,
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.bookings.details, {
      booking: state => state.data,
    }),
    ...mapState(DOMAINS_MODEL.app.comments, {
      comments: state => state.comments.data || [],
      commentsStatus: state => state.comments.STATUS,
    }),
  },
  created() {
    this.getCommentsByUuid();
  },
  methods: {
    async getCommentsByUuid() {
      this.clearData();
      await this.getCommentsByType({
        commentableUuid: this.booking.uuid,
        commentableType: COMMENTABLE_TYPES.booking,
      });
    },
    ...mapActions(DOMAINS_MODEL.app.comments, [
      'getCommentsByType',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.comments, [
      'clearData',
    ]),
  },
};
</script>

<template>
  <div
    class="DetailsCommentsView"
    data-test-id="details_comments-view"
  >
    <CommentsList
      :comments="comments"
      :is-loading="commentsStatus.LOADING"
      :entity-uuid="booking.uuid"
      :callback="getCommentsByUuid"
      entity="booking"
      data-test-id="list"
    />
  </div>
</template>
