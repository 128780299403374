<script>
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import map from 'lodash/map';
import replace from 'lodash/replace';
import { mapState } from 'vuex';
import {
  MuiInputText,
  MuiSelect,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { sentenceCase } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { ContentCellComponent } from '@/components';
import { PATTERN_INPUT_VALIDATIONS, toDecimalFormat } from '@/utils';
import { BOOKING_DETAIL_SCOPES } from '../../store/BookingDetailsModule';

export default {
  name: 'FraudCostsFormComponent',
  components: {
    ContentCellComponent,
    MuiInputText,
    MuiSelect,
    MuiValidationWrapper,
  },
  directives: {
    Validate,
  },
  model: {
    prop: 'costs',
    event: 'update:costs',
  },
  props: {
    /**
     * [
     *  { type: 'cost_type', cost: '10.45' },
     * ]
     */
    costs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      inputs: {
        type: null,
        cost: '',
      },
      isFormValid: true,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      currencySymbol: state => state.operators.configuration.currencySymbol,
    }),
    ...mapState(DOMAINS_MODEL.carsharing.bookings.details, {
      fraudValues: state => state[BOOKING_DETAIL_SCOPES.fraudValues].data,
    }),
    fraudCostTypes() {
      return this.fraudValues.costs;
    },
    fraudCostTypesNormalized() {
      return map(this.fraudCostTypes, cost => ({
        label: sentenceCase(cost),
        value: cost,
        disabled: find(this.costs, { type: cost }),
      }));
    },
  },
  created() {
    this.PATTERN_INPUT_VALIDATIONS = PATTERN_INPUT_VALIDATIONS;
  },
  methods: {
    sentenceCase,
    toDecimalFormat,
    add() {
      const newCosts = cloneDeep(this.costs);
      newCosts.push({
        type: this.inputs.type,
        cost: this.inputs.cost,
      });

      this.$emit('update:costs', newCosts);

      this.inputs.type = null;
      this.inputs.cost = '';

      this.$nextTick(() => {
        this.$refs.typeSelector.$el.MuiValidationManager.reset();
        this.$refs.costInput.$el.MuiValidationManager.reset();
      });
    },
    remove(costIndex) {
      const newCosts = cloneDeep(this.costs);
      newCosts.splice(costIndex, 1);
      this.$emit('update:costs', newCosts);
    },
    isBiggerThan0Validation(value) {
      const normalizedValue = replace(value, ',', '.');
      const message = 'The amount must be greater than or equal to 0,01';
      let isValid = false;

      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(normalizedValue)) {
        const numericValue = parseFloat(normalizedValue);
        isValid = numericValue > 0;
      }

      return { isValid, message };
    },
  },
};
</script>

<template>
  <ContentCellComponent
    label="Fraud cost"
    class="FraudCostFormComponent"
  >
    <MuiValidationWrapper
      class="d-flex w-100"
      @areAllValid="isValid => isFormValid = isValid"
    >
      <div class="flex-fill-0">
        <MuiSelect
          ref="typeSelector"
          v-model="inputs.type"
          v-validate="{
            isRequired: true,
          }"
          :options="fraudCostTypesNormalized"
          placeholder="Select cost"
          name="type"
          class="w-100"
        />
      </div>
      <div class="flex-fill-0">
        <MuiInputText
          ref="costInput"
          v-validate="{
            isRequired: true,
            isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,
            isBiggerThan0Validation,
          }"
          :model-value="inputs.cost"
          placeholder="Enter number value (e.g. 10,30)"
          class="ml-1"
          name="cost"
          @blur="newCost => inputs.cost = toDecimalFormat(newCost)"
        />
      </div>
      <ui-button
        :disabled="!isFormValid"
        :face="FACES.outline"
        class="FraudCostFormComponent__add ml-1"
        @clickbutton="add"
      >
        Add
      </ui-button>
    </MuiValidationWrapper>
    <div
      v-if="costs.length"
      class="d-flex flex-column w-100 mt-3"
    >
      <div
        v-for="(costLine, index) in costs"
        :key="costLine.type"
        class="d-flex align-items-center py-2 w-100 emobg-border-bottom-1 emobg-border-color-ground-lighter"
      >
        <div class="flex-fill-0">
          {{ sentenceCase(costLine.type) }}
        </div>
        <div class="flex-fill-0">
          {{ costLine.cost }}{{ currencySymbol }}
        </div>
        <div
          class="d-flex justify-content-center"
          style="flex: 0 0 58px;"
        >
          <ui-tooltip
            :placement="PLACEMENTS.top"
            tooltip="Remove"
          >
            <ui-icon
              :icon="ICONS.minus"
              class="cursor-pointer"
              @click="() => remove(index)"
            />
          </ui-tooltip>
        </div>
      </div>
    </div>
  </ContentCellComponent>
</template>

<style lang="scss">
.FraudCostFormComponent {
  &__add .Ui-Button {
    min-width: 0;
  }
}
</style>
