<script>
import each from 'lodash/each';
import get from 'lodash/get';
import map from 'lodash/map';
import values from 'lodash/values';
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import { reactive, ref } from 'vue';
import {
  MuiInputText,
  MuiSelect,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { MuiDatePicker } from '@emobg/vue-base';
import { DATE_FORMAT, sentenceCase } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { PageView } from '@/components';
import { DATE_INPUT_VALIDATIONS, PATTERN_INPUT_VALIDATIONS, toNumberUtil } from '@/utils';
import { BOOKING_MANAGEMENT_SCOPES } from './store/BookingDetailsModule';
import {
  BOOKING_STATUS,
  BOOKING_USAGE_STATUS,
} from '../const/booking.const';

export default {
  name: 'BookingManagementView',
  directives: {
    Validate,
  },
  components: {
    MuiDatePicker,
    MuiInputText,
    MuiSelect,
    MuiValidationWrapper,
    PageView,
  },
  setup() {
    const bookingUuid = ref('');
    const inputs = reactive({
      usageStatus: null,
      status: null,
      kmStart: '',
      kmEnd: '',
      realStart: null,
      realEnd: null,
      returnGpsLat: '',
      returnGpsLng: '',
    });
    const isFormValid = ref(false);
    const usageStatusOptions = map(values(BOOKING_USAGE_STATUS), usageStatus => ({
      label: sentenceCase(usageStatus),
      value: usageStatus,
    }));
    const statusOptions = map(values(BOOKING_STATUS), status => ({
      label: sentenceCase(status),
      value: status,
    }));

    return {
      DATE_INPUT_VALIDATIONS,
      PATTERN_INPUT_VALIDATIONS,
      bookingUuid,
      inputs,
      isFormValid,
      statusOptions,
      usageStatusOptions,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.bookingMangement, {
      managementStatus: state => get(state, `${BOOKING_MANAGEMENT_SCOPES.usageStatusForm}.STATUS`),
    }),
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carsharing.bookingMangement, ['postBookingUsage']),
    async bookingUsageRequest() {
      this.$refs.form.validateAll();

      if (this.isFormValid) {
        const {
          realStart,
          realEnd,
        } = this.inputs;
        await this.postBookingUsage({
          bookingUuid: this.bookingUuid,
          data: {
            ...this.inputs,
            realStart: moment.isMoment(realStart) && realStart.isValid()
              ? realStart.utc().format(DATE_FORMAT.filter)
              : null,
            realEnd: moment.isMoment(realEnd) && realEnd.isValid()
              ? realEnd.utc().format(DATE_FORMAT.filter)
              : null,
            kmStart: toNumberUtil(this.inputs.kmStart, false),
            kmEnd: toNumberUtil(this.inputs.kmEnd, false),
            returnGpsLat: toNumberUtil(this.inputs.returnGpsLat),
            returnGpsLng: toNumberUtil(this.inputs.returnGpsLng),
          },
        });

        if (!this.managementStatus.ERROR) {
          this.$notify({
            message: 'Booking edited',
            textAction: 'OK',
          });
          this.bookingUuid = '';
          this.inputs.usageStatus = null;
          this.inputs.status = null;
          this.inputs.kmStart = '';
          this.inputs.kmEnd = '';
          this.inputs.realStart = null;
          this.inputs.realEnd = null;
          this.inputs.returnGpsLat = '';
          this.inputs.returnGpsLng = '';
          this.$nextTick(() => {
            each(this.$refs.form.validationManagers, validationManager => validationManager.reset());
          });
        }
      }
    },
  },
};
</script>

<template>
  <PageView data-test-id="booking-management_view">
    <h1 class="mb-2">
      Booking Management
    </h1>
    <ui-card>
      <MuiValidationWrapper
        ref="form"
        @areAllValid="isValid => isFormValid = isValid"
      >
        <div class="row">
          <div class="col-6 mb-4">
            <MuiInputText
              v-model="bookingUuid"
              v-validate="{
                isRequired: true,
                isPattern: PATTERN_INPUT_VALIDATIONS.uuid,
              }"
              name="bookingUuid"
              label="Booking uuid*"
              placeholder="Enter the booking uuid"
              data-test-id="booking_uuid-input"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6 mb-4">
            <MuiSelect
              v-model="inputs.usageStatus"
              v-validate="{
                isRequired: true,
              }"
              :options="usageStatusOptions"
              name="usageStatus"
              label="Usage status*"
              placeholder="Select the usage status"
              class="w-100"
              data-test-id="usage_status-select"
            />
          </div>
          <div class="col-6 mb-4">
            <MuiSelect
              v-model="inputs.status"
              :options="statusOptions"
              name="status"
              label="Status"
              placeholder="Select the status"
              class="w-100"
              data-test-id="status-select"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6 mb-4">
            <MuiInputText
              v-model="inputs.kmStart"
              v-validate="{
                isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,
              }"
              name="kmStart"
              label="Km start"
              placeholder="Enter the km start"
              data-test-id="km_start-input"
            />
          </div>
          <div class="col-6 mb-4">
            <MuiInputText
              v-model="inputs.kmEnd"
              v-validate="{
                isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,
              }"
              name="kmEnd"
              label="Km end"
              placeholder="Enter the km end"
              data-test-id="km_end-input"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6 mb-4">
            <MuiDatePicker
              v-model="inputs.realStart"
              v-validate="{
                isValidDate: DATE_INPUT_VALIDATIONS.validDate,
              }"
              :size="SIZES.small"
              label="Real start"
              placeholder="Enter the real start date"
              class="w-100"
              data-test-id="real_start-input"
            />
          </div>
          <div class="col-6 mb-4">
            <MuiDatePicker
              v-model="inputs.realEnd"
              v-validate="{
                isValidDate: DATE_INPUT_VALIDATIONS.validDate,
              }"
              :size="SIZES.small"
              label="Real end"
              placeholder="Enter the real end date"
              class="w-100"
              data-test-id="real_end-input"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6 mb-4">
            <MuiInputText
              v-model="inputs.returnGpsLat"
              v-validate="{
                isPattern: PATTERN_INPUT_VALIDATIONS.floatNumber,
              }"
              name="returnGpsLat"
              label="Return gps lat"
              placeholder="Enter the return gps lat"
              data-test-id="km_start-input"
            />
          </div>
          <div class="col-6 mb-4">
            <MuiInputText
              v-model="inputs.returnGpsLng"
              v-validate.input="{
                isPattern: PATTERN_INPUT_VALIDATIONS.floatNumber,
              }"
              name="returnGpsLng"
              label="Return gps lng"
              placeholder="Enter the return gps lng"
              data-test-id="km_start-input"
            />
          </div>
        </div>
      </MuiValidationWrapper>
      <div class="d-flex justify-content-end">
        <ui-button
          :loading="managementStatus.LOADING"
          @clickbutton="bookingUsageRequest"
        >
          Save
        </ui-button>
      </div>
    </ui-card>
  </PageView>
</template>
