<script>
import get from 'lodash/get';
import isNil from 'lodash/isNil';

import { mapActions, mapState } from 'vuex';
import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  formatUtc,
  isNilString,
  reformatDateTime,
  sentenceCase,
} from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import FUEL_TYPES from '@domains/Carsharing/Vehicles/const/fuelTypes.const';
import crm from '@domains/CRM/router/CRMRouterMap';
import fleetRoutes from '@domains/Fleet/router/FleetRouterMap';
import { AlertsTemplate } from '@/templates';
import {
  ConnectivityStatusComponent,
  ContentCellComponent,
  CopyToClipboard,
  OverviewCollapse,
  PermissionLink,
} from '@/components';
import BookingTypeBadge from '../../../../components/BookingTypeBadge/BookingTypeBadge';
import BatteryLevel from '../../../../components/BatteryLevel/BatteryLevel';
import BookingSetup from '../../../../Planning/components/BookingSetup/BookingSetup';
import { BOOKING_TYPES } from '../../../../const/bookingTypes';
import { timezoneCorrector } from '../../../../Planning/config';
import { ENDED_USAGE_STATUS } from '../../../../Planning/const';
import fleet from '../../../../router/FleetRouterMap';
import { formatNil } from '../../../../utils/formatters';

export default {
  components: {
    AlertsTemplate,
    BatteryLevel,
    BookingTypeBadge,
    BookingSetup,
    ConnectivityStatusComponent,
    ContentCellComponent,
    CopyToClipboard,
    OverviewCollapse,
    PermissionLink,
  },
  data() {
    return {
      isModalVisible: false,
      setupData: null,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.bookings.details, {
      booking: state => state.data,
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone,
      mileageUnit: state => get(state, 'operators.mileageUnit'),
    }),
    isElectric() {
      return get(this.booking, 'vehicle.fuelType') === FUEL_TYPES.electric;
    },
    isNonConnected() {
      return get(this, 'booking.nonConnected');
    },
  },
  created() {
    this.BOOKING_TYPES = BOOKING_TYPES;
    this.CARSHARING_PERMISSIONS = CARSHARING_PERMISSIONS;
    this.CRM_PERMISSIONS = CRM_PERMISSIONS;
    this.DATE_FORMAT = DATE_FORMAT;
    this.ENDED_USAGE_STATUS = ENDED_USAGE_STATUS;
    this.fleet = fleet;
    this.crm = crm;
    this.fleetRoutes = fleetRoutes;
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carsharing.bookings.details, [
      'getBooking',
    ]),
    get,
    isNil,
    sentenceCase,
    isNilString,
    formatUtc,
    formatNil,
    reformatDateTime,
    openBookingSetup() {
      this.setupData = {
        userUuid: get(this, 'booking.user.uuid'),
        start: timezoneCorrector(get(this, 'booking.start'), this.operatorTimezone, DATE_FORMAT.defaultExtendedSeconds, DATE_FORMAT.defaultExtendedSeconds),
        end: timezoneCorrector(get(this, 'booking.end'), this.operatorTimezone, DATE_FORMAT.defaultExtendedSeconds, DATE_FORMAT.defaultExtendedSeconds),
        bookingType: get(this, 'booking.isUnavailability') ? 'unavailability' : get(this, 'booking.type'),
        vehicleUuid: get(this, 'booking.vehicle.uuid'),
        locationUuid: get(this, 'booking.location.uuid'),
        returnLocationUuid: get(this, 'booking.returnLocation.uuid'),
        cityUuid: get(this, 'booking.location.cityUuid'),
      };
      this.isModalVisible = true;
    },
    closeBookingSetup() {
      this.isModalVisible = false;
      this.setupData = null;
      this.getBooking(get(this, 'booking.uuid'));
    },
    formatBookingDate(value, dateFormat, timezone) {
      if (isNil(value)) {
        return FALLBACK_MESSAGE.dash;
      }
      return reformatDateTime(value, dateFormat, timezone);
    },
  },
};
</script>
<template>
  <div
    class="BookingDetailsHeader"
    data-test-id="booking_details-header"
  >
    <div class="row">
      <div class="col-12">
        <OverviewCollapse id="bookingOverview">
          <template #header>
            <div class="d-flex flex-wrap align-items-center">
              <h2 class="emobg-font-weight-semibold mr-3">
                Booking #{{ booking.id }}
              </h2>
              <div>
                <ui-badge
                  :color="GRAYSCALE.ground"
                  class="mr-2"
                  data-test-id="system_status-badge"
                >
                  <div class="d-flex align-items-center">
                    <ui-icon
                      :icon="ICONS.systemStatus"
                      :size="ICONS_SIZES.xSmall"
                      class="mr-2"
                    />
                    {{ formatNil(sentenceCase(booking.systemStatus)) }}
                  </div>
                </ui-badge>
                <ui-badge
                  :color="GRAYSCALE.ground"
                  class="mr-2"
                  data-test-id="vehicle_status-badge"
                >
                  <div class="d-flex align-items-center">
                    <ui-icon
                      :icon="ICONS.vehicleStatus"
                      :size="ICONS_SIZES.xSmall"
                      class="mr-2"
                    />
                    {{ booking.isUnavailability ? FALLBACK_MESSAGE.notAvailable : formatNil(sentenceCase(booking.vehicleStatus)) }}
                  </div>
                </ui-badge>

                <ui-badge
                  v-if="isNonConnected"
                  :color="COLORS.warning"
                  square
                  class="mr-2"
                  data-test-id="non_connected-badge"
                >
                  Non-connected
                </ui-badge>

                <ui-badge
                  v-if="get(booking, 'isAutoCancelled')"
                  :color="COLORS.warning"
                  :square="true"
                  data-test-id="auto_cancelled-badge"
                >
                  Auto-cancelled
                </ui-badge>
              </div>
            </div>
          </template>
          <template #actions>
            <ui-button
              v-if="!ENDED_USAGE_STATUS.includes(get(booking, 'vehicleStatus'))"
              class="ml-3"
              data-test-id="booking_details-edit-button"
              @clickbutton="openBookingSetup"
            >
              Edit booking
            </ui-button>
          </template>
          <div class="pt-4">
            <ui-alert
              v-if="get(booking, 'hasFraud')"
              :color="COLORS.danger"
              :icon="ICONS.alertFull"
              class="pb-4 d-block w-100"
              data-test-id="fraud-alert"
            >
              Fraud reported on this booking
            </ui-alert>
            <div class="row">
              <div class="col-md-6 col-lg-3">
                <ContentCellComponent
                  label="Operator"
                  is-on-header
                  class="mb-4"
                >
                  <p class="mb-1">
                    {{ get(booking, 'operator.name', FALLBACK_MESSAGE.dash) }}
                  </p>
                  <span class="emobg-color-ink-light">City: {{ get(booking, 'location.city', FALLBACK_MESSAGE.dash) }}</span>
                </ContentCellComponent>

                <ContentCellComponent
                  label="Booking type"
                  is-on-header
                  class="mb-4"
                >
                  <BookingTypeBadge
                    :type="booking.isUnavailability ? 'unavailability' : booking.type"
                    :is-automatic="get(booking, 'isAutomatic', false)"
                  />
                  <p
                    v-if="booking.type === BOOKING_TYPES.intervention && get(booking, 'interventionType.name')"
                    class="mt-1 emobg-color-ink-light"
                  >
                    Type: {{ booking.interventionType.name }}
                  </p>
                </ContentCellComponent>
                <ContentCellComponent
                  :value="formatBookingDate(booking.createdAt, DATE_FORMAT.defaultExtendedSeconds, operatorTimezone)"
                  label="Creation date"
                  is-on-header
                  class="mb-4"
                />
                <ContentCellComponent
                  label="Start date"
                  is-on-header
                  class="mb-4"
                >
                  <p class="mb-1">
                    {{ formatBookingDate(booking.start, DATE_FORMAT.defaultExtendedSeconds, operatorTimezone) }}
                  </p>
                  <span
                    v-if="booking.realStart"
                    class="emobg-color-ink-light"
                  >
                    Real start:
                    {{ formatBookingDate(booking.realStart, DATE_FORMAT.defaultExtendedSeconds, operatorTimezone) }}
                  </span>
                </ContentCellComponent>
                <ContentCellComponent
                  label="End date"
                  is-on-header
                  class="mb-4"
                >
                  <p class="mb-1">
                    {{ formatBookingDate(booking.end, DATE_FORMAT.defaultExtendedSeconds, operatorTimezone) }}
                  </p>
                  <span
                    v-if="booking.realEnd"
                    class="emobg-color-ink-light"
                  >
                    Real end:
                    {{ formatBookingDate(booking.realEnd, DATE_FORMAT.defaultExtendedSeconds, operatorTimezone) }}
                  </span>
                </ContentCellComponent>
                <ContentCellComponent
                  v-if="booking.mileage"
                  :value="booking.mileage ? `${booking.mileage} ${mileageUnit}` : FALLBACK_MESSAGE.dash"
                  label="Mileage used"
                  is-on-header
                  class="mb-4"
                />
              </div>
              <div class="col-md-6 col-lg-3">
                <ContentCellComponent
                  label="User"
                  is-on-header
                  class="mb-4"
                >
                  <div
                    v-if="get(booking, 'user.firstTimeBooking')"
                    class="mb-1"
                  >
                    <ui-badge
                      :color="COLORS.warning"
                      text="First time booking"
                    />
                  </div>
                  <p v-if="isNilString(get(booking, 'user.name')) || isNil(get(booking, 'user.id'))">
                    {{ FALLBACK_MESSAGE.dash }}
                  </p>
                  <PermissionLink
                    v-else
                    :link-permissions="[CRM_PERMISSIONS.viewCrmUsers]"
                    :to="{
                      name: crm.users.detail.bookings,
                      params: {
                        userUuid: booking.user.uuid,
                      },
                    }"
                    data-test-id="booking_details-user-link"
                    class="emobg-font-weight-semibold"
                    target="_blank"
                  >
                    {{ sentenceCase(get(booking, 'user.name')) }}
                  </PermissionLink>
                  <p class="mt-1">
                    <CopyToClipboard
                      v-if="get(booking, 'user.email')"
                      data-test-id="booking_details-copy-email-button"
                    >
                      {{ booking.user.email }}
                    </CopyToClipboard>
                    <template v-else>
                      {{ FALLBACK_MESSAGE.dash }}
                    </template>
                  </p>
                  <p class="mt-1">
                    {{ get(booking, 'user.phone', FALLBACK_MESSAGE.dash) }}
                  </p>
                </ContentCellComponent>
                <ContentCellComponent
                  label="Booking profile"
                  is-on-header
                  class="mb-4"
                >
                  <ui-badge
                    :color="GRAYSCALE.ground"
                    :text="sentenceCase(booking.userProfile.type)"
                  />
                </ContentCellComponent>
                <ContentCellComponent
                  :value="sentenceCase(booking.insuranceType)"
                  label="Insurance type"
                  is-on-header
                  class="mb-4"
                />
                <ContentCellComponent
                  v-if="!isNonConnected"
                  label="Alerts"
                  is-on-header
                  class="mb-4"
                >
                  <AlertsTemplate :alerts="booking.alerts" />
                </ContentCellComponent>
              </div>
              <div class="col-md-6 col-lg-3">
                <ContentCellComponent
                  label="Vehicle"
                  is-on-header
                  class="mb-4"
                >
                  <PermissionLink
                    :link-permissions="[CARSHARING_PERMISSIONS.viewCarsharingVehicles]"
                    :to="{
                      name: fleet.vehicles.detail.details,
                      params: {
                        vehicle_uuid: booking.vehicle.uuid,
                      },
                    }"
                    data-test-id="booking_details-vehicle-link"
                    class="emobg-font-weight-semibold"
                    target="_blank"
                  >
                    {{ formatNil(booking.vehicle.licensePlate) }}
                  </PermissionLink>
                  <p class="mb-1">
                    {{ booking.vehicle.brand }}
                    {{ booking.vehicle.model }}
                    {{ booking.vehicle.color ? `(${booking.vehicle.color})` : FALLBACK_MESSAGE.dash }}
                  </p>
                  <p class="emobg-color-ink-light">
                    Category: {{ booking.vehicle.category || FALLBACK_MESSAGE.dash }}
                  </p>
                </ContentCellComponent>
                <ContentCellComponent
                  v-if="!isNonConnected"
                  label="Connectivity"
                  class="mb-4"
                  is-on-header
                >
                  <ConnectivityStatusComponent
                    :status="get(booking, 'vehicleTelemetry.connectivityStatus')"
                    show-tooltip
                    class="my-2 emobg-body-2"
                  />
                  <p
                    v-if="get(booking, 'vehicleTelemetry.connectivityTimestamp')"
                    class="emobg-body-1"
                  >
                    Updated: {{ formatUtc(booking.vehicleTelemetry.connectivityTimestamp, DATE_FORMAT.defaultExtended, operatorTimezone) }}
                  </p>
                </ContentCellComponent>
                <ContentCellComponent
                  v-if="!isNonConnected"
                  :label="isElectric ? 'Traction battery' : 'Fuel level'"
                  class="mb-4"
                  is-on-header
                >
                  <BatteryLevel
                    v-if="isElectric"
                    :battery-level="get(booking, 'vehicleTelemetry.fuelLevel')"
                    :danger-level="get(booking, 'vehicleTelemetry.fuelDangerLevel', 0)"
                    :icon-size="ICONS_SIZES.xLarge"
                  />
                  <template v-else>
                    <div class="d-flex align-items-center">
                      <ui-icon
                        :icon="ICONS.fuelDispenser"
                        :size="SIZES.medium"
                        class="mr-2"
                      />
                      <span class="emobg-body-2">
                        {{ get(booking, 'vehicleTelemetry.fuelLevel')
                          ? `${get(booking, 'vehicleTelemetry.fuelLevel')}%`
                          : FALLBACK_MESSAGE.noData }}
                      </span>
                    </div>
                  </template>
                </ContentCellComponent>
                <ContentCellComponent
                  v-if="!isNonConnected"
                  label="Battery level"
                  class="mb-4"
                  is-on-header
                >
                  <div class="d-flex align-items-center mb-1">
                    <ui-icon
                      :icon="ICONS.carBattery"
                      :size="SIZES.medium"
                      class="mr-2"
                    />
                    <div class="emobg-body-2">
                      {{ get(booking, 'vehicleTelemetry.batteryLevel')
                        ? `${get(booking, 'vehicleTelemetry.batteryLevel')}V`
                        : FALLBACK_MESSAGE.noData }}
                    </div>
                  </div>
                  <span
                    v-if="get(booking, 'vehicleTelemetry.batteryLevel')"
                    class="emobg-body-1 "
                  >
                    Updated:
                    {{ formatUtc(get(booking, 'vehicleTelemetry.batteryLevelTimestamp'), DATE_FORMAT.defaultExtended, operatorTimezone) }}
                  </span>
                </ContentCellComponent>
              </div>
              <div class="col-md-6 col-lg-3">
                <ContentCellComponent
                  :label="booking.isOneWay ? 'Pickup location' : 'Location'"
                  class="mb-4"
                  is-on-header
                >
                  <PermissionLink
                    :link-permissions="[CARSHARING_PERMISSIONS.viewCarsharingLocations]"
                    :to="{
                      name: fleetRoutes.locations.detail.index,
                      params: {
                        locationUuid: booking.location.uuid,
                      },
                    }"
                    data-test-id="booking_details-location-link"
                    class="emobg-font-weight-semibold"
                  >
                    {{ get(booking, 'location.name') }}
                  </PermissionLink>
                  <p>
                    {{ get(booking, 'location.neighborhood') ? `${booking.location.neighborhood},` : '' }}
                    {{ get(booking, 'location.city') }}
                  </p>
                  <p class="emobg-color-ink-light">
                    Location type: {{ get(booking, 'location.type', FALLBACK_MESSAGE.dash) }}
                  </p>
                  <p
                    v-if="get(booking, 'location.address')"
                    class="emobg-color-ink-light"
                  >
                    {{ booking.location.address }}
                  </p>
                </ContentCellComponent>
                <ContentCellComponent
                  v-if="booking.isOneWay && booking.returnLocation"
                  label="Return location"
                  class="mb-4"
                  is-on-header
                >
                  <PermissionLink
                    :link-permissions="[CARSHARING_PERMISSIONS.viewCarsharingLocations]"
                    :to="{
                      name: fleetRoutes.locations.detail.index,
                      params: {
                        locationUuid: booking.returnLocation.uuid,
                      },
                    }"
                    data-test-id="booking_details-return_location-link"
                    class="emobg-font-weight-semibold"
                  >
                    {{ get(booking, 'returnLocation.name') }}
                  </PermissionLink>
                  <p>
                    {{ get(booking, 'returnLocation.neighborhood') ? `${booking.returnLocation.neighborhood},` : '' }}
                    {{ get(booking, 'returnLocation.city') }}
                  </p>
                  <p class="emobg-color-ink-light">
                    Location type: {{ get(booking, 'returnLocation.type', FALLBACK_MESSAGE.dash) }}
                  </p>
                  <p
                    v-if="get(booking, 'returnLocation.address')"
                    class="emobg-color-ink-light"
                  >
                    {{ booking.returnLocation.address }}
                  </p>
                </ContentCellComponent>
              </div>
            </div>
          </div>
        </OverviewCollapse>
      </div>
    </div>
    <BookingSetup
      v-if="isModalVisible && booking"
      :success="closeBookingSetup"
      :initial="setupData"
      :booking-uuid="booking.uuid"
      @closeModal="closeBookingSetup"
      @modal-closed="closeBookingSetup"
    />
  </div>
</template>
