import { render, staticRenderFns } from "./CreateAdditionalCost.vue?vue&type=template&id=794f6415&"
import script from "./CreateAdditionalCost.vue?vue&type=script&lang=js&"
export * from "./CreateAdditionalCost.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('794f6415')) {
      api.createRecord('794f6415', component.options)
    } else {
      api.reload('794f6415', component.options)
    }
    module.hot.accept("./CreateAdditionalCost.vue?vue&type=template&id=794f6415&", function () {
      api.rerender('794f6415', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/domains/Carsharing/Bookings/Details/AdditionalCosts/components/CreateAdditionalCost.vue"
export default component.exports