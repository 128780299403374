<script>
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import toUpper from 'lodash/toUpper';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';

import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { MuiModal } from '@emobg/motion-ui/v1';
import { COLORS } from '@emobg/vue-base';
import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  reformatDateTime,
  sentenceCase,
} from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton, TableComponent } from '@/components';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';
import { refreshAlgoliaStore } from '@/utils';
import { DeleteButtonTemplate, TableTemplate } from '@/templates';
import CreateAdditionalCost from './components/CreateAdditionalCost';
import { SETTLEMENT_STATUS_COLORS } from './constants';
import { parseCost } from './utils';
import { COST_TYPES_VALUES } from './components/constants';
import { settlementStatus } from './config';

export default {
  components: {
    CancelButton,
    CreateAdditionalCost,
    MuiModal,
    TableComponent,
  },
  data() {
    return {
      isCreateModalOpen: false,
      isSendModalOpen: false,
      isRefundModalOpen: false,
      currentSettlement: undefined,
      specialChargeSettlement: undefined,
      isAddSpecialCharge: false,
      isEditSpecialCharge: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.additionalCosts, {
      costs: state => state.costs.data,
      costsStatus: state => get(state, 'costs.STATUS', {}),
      refundStatus: state => get(state, 'refund.STATUS', {}),
      specialChargeError: state => get(state, 'specialCharge.STATUS.ERROR'),
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      userUuid: state => get(state, 'user.data.uuid'),
    }),

    ...mapGetters(DOMAINS_MODEL.carsharing.additionalCosts, [
      'settlements',
    ]),

    isCreateCostDisabled() {
      const uniqueTypes = uniqBy(this.costs, 'type');
      return get(uniqueTypes, 'length') > 2;
    },
  },
  async created() {
    this.bookingUuid = get(this.$route, 'params.bookingUuid');

    this.modalConfig = {
      header: {
        class: 'pl-3',
        isClosable: true,
      },
    };

    this.settlementsActions = [
      {
        label: 'Refund',
        action: result => {
          this.isRefundModalOpen = true;
          this.currentSettlement = result;
        },
        hideAction: result => toUpper(result.status) !== toUpper(settlementStatus.billed),
      },
      {
        label: 'Add special charge',
        action: result => {
          this.isAddSpecialCharge = true;
          this.isCreateModalOpen = true;
          this.specialChargeSettlement = result;
        },
        hideAction: result => !(result.status === settlementStatus.created && !this.hasSpecialCharge(result) && this.hasDistanceAndDayCosts(result)),
      },
      {
        label: 'Edit special charge',
        action: result => {
          this.isEditSpecialCharge = true;
          this.isCreateModalOpen = true;
          this.specialChargeSettlement = result;
        },
        hideAction: result => !(result.status === settlementStatus.created && this.hasSpecialCharge(result) && this.hasDistanceAndDayCosts(result)),
      },
    ];

    this.settlementsDraftSchema = [
      {
        header: 'UUID',
        width: 325,
      },
      {
        header: 'Billing date',
        width: 325,
      },
      {
        header: 'Refund date',
        width: 325,
      },
      {
        header: 'Status',
        component: BadgeComponent,
        props: () => ({
          text: 'Draft',
          color: COLORS.moderate,
        }),
      },
      {
        component: DeleteButtonTemplate,
        props: result => ({
          onClick: () => this.deleteCost(result),
        }),
        width: 52,
      },
      {
        component: TableTemplate,
        collapsable: true,
        collapsed: true,
        props: rowData => ({
          labels: [
            'Type of cost',
            'Type of transaction',
            'Price per unit',
            'Units',
            'Total net amount',
            'VAT',
            'Total amount',
            'Comment',
          ],
          items: this.parseSettlementCosts({ settlementLines: [rowData] }),
        }),
      },
    ];

    this.settlementsSchema = [
      {
        header: 'UUID',
        template: 'uuid',
        width: 325,
      },
      {
        header: 'Billing date',
        template: 'settlementDate',
        width: 325,
      },
      {
        header: 'Refund date',
        template: rowData => reformatDateTime(rowData.refundedDate, DATE_FORMAT.defaultExtended),
        width: 325,
      },
      {
        header: 'Status',
        component: BadgeComponent,
        props: ({ status }) => ({
          text: sentenceCase(status),
          color: SETTLEMENT_STATUS_COLORS[status],
        }),
        minWidth: 150,
      },
      {
        component: TableTemplate,
        collapsable: true,
        collapsed: true,
        props: rowData => ({
          labels: [
            'Type of cost',
            'Type of transaction',
            'Price per unit',
            'Units',
            'Total net amount',
            'VAT',
            'Total amount',
            'Comment',
          ],
          items: this.parseSettlementCosts(rowData),
        }),
      },
    ];

    this.loadSettlements();
  },

  beforeDestroy() {
    this.resetCosts();
  },

  methods: {
    refreshAlgoliaStore,
    ...mapMutations(DOMAINS_MODEL.carsharing.additionalCosts, [
      'addCost',
      'resetCosts',
      'deleteCost',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications, [
      'notify',
    ]),
    ...mapActions(DOMAINS_MODEL.carsharing.additionalCosts, [
      'postAdditionalCosts',
      'patchAdditionalCosts',
      'postSpecialCharge',
      'patchSpecialCharge',
      'getSettlementsByBookingUuid',
    ]),
    hasSpecialCharge(settlement) {
      const settlementLines = get(settlement, 'settlementLines', []);
      return !!find(settlementLines, ['type', COST_TYPES_VALUES.specialCharges]);
    },
    hasDistanceAndDayCosts(settlement) {
      const settlementLines = get(settlement, 'settlementLines', []);
      return !!(find(settlementLines, ['type', COST_TYPES_VALUES.dayCost]) && find(settlementLines, ['type', COST_TYPES_VALUES.distance]));
    },
    createSettlement() {
      this.postAdditionalCosts({
        bookingUuid: this.bookingUuid,
        userUuid: this.userUuid,
      }).then(() => {
        this.isSendModalOpen = false;
        if (!this.costsStatus.ERROR) {
          this.resetCosts();
          this.refreshAdditionalCostsTable();
          this.notify({ message: 'New cost added successfully' });
        }
      });
    },
    refundSettlement() {
      this.patchAdditionalCosts({
        bookingUuid: get(this, 'currentSettlement.offeredService.externalServiceUuid'),
        settlementUuid: get(this, 'currentSettlement.uuid'),
        userUuid: this.userUuid,
      }).then(() => {
        this.isRefundModalOpen = false;
        if (!this.refundStatus.ERROR) {
          this.refreshAdditionalCostsTable();
          this.notify({ message: 'Refund done successfully' });
        }
      });
    },
    refreshAdditionalCostsTable() {
      this.refreshAlgoliaStore(this.$refs.additionalCostsTable, 2000);
    },
    parseSettlementCosts(result) {
      const settlementLines = sortBy(get(result, 'settlementLines'), 'type');
      return map(settlementLines, item => [
        sentenceCase(get(item, 'type')) || FALLBACK_MESSAGE.dash,
        get(item, 'credit') ? 'Credit' : 'Debit',
        get(item, 'costPerUnit', FALLBACK_MESSAGE.dash),
        get(item, 'units', FALLBACK_MESSAGE.dash),
        `${get(item, 'totalNetAmount', '0.00')}€`,
        `${get(item, 'taxPercentage', '0.00')}%`,
        `${get(item, 'totalAmount', '0.00')}€`,
        get(item, 'comment', FALLBACK_MESSAGE.dash),
      ]);
    },
    async onSaveAdditionalCostLine(cost) {
      if (!this.isAddSpecialCharge && !this.isEditSpecialCharge) {
        this.addCost(cost);
      } else {
        const specialChargeConfig = this.isAddSpecialCharge
          ? { request: this.postSpecialCharge, message: 'Special charge added successfully' }
          : { request: this.patchSpecialCharge, message: 'Special charge edited successfully' };

        await specialChargeConfig.request({
          externalServiceUuid: get(this.specialChargeSettlement, 'offeredService.externalServiceUuid'),
          settlementUuid: get(this.specialChargeSettlement, 'uuid'),
          specialCharge: {
            ...parseCost(cost),
            userUuid: this.userUuid,
          },
        });
        if (!this.specialChargeError) {
          this.notify({ message: specialChargeConfig.message });
          this.refreshAdditionalCostsTable();
        }
        this.isAddSpecialCharge = false;
        this.isEditSpecialCharge = false;
      }
    },

    async loadSettlements() {
      await this.getSettlementsByBookingUuid({
        bookingUuid: this.bookingUuid,
        userUuid: this.userUuid,
      });
    },
  },
};
</script>

<template>
  <div data-test-id="additional_costs-view">
    <h1 class="mb-2">
      Additional costs
    </h1>
    <ui-card
      class="mb-4 d-block"
      data-test-id="draft-card"
    >
      <div class="d-flex justify-content-between align-items-center">
        <h2 class="emobg-font-weight-semibold">
          Settlement draft
        </h2>

        <div class="d-flex">
          <ui-button
            :face="FACES.outline"
            :disabled="!costs.length"
            class="mr-3"
            data-test-id="send-button"
            @clickbutton="isSendModalOpen = true"
          >
            Create settlement from costs ({{ costs.length }})
          </ui-button>
          <ui-button
            :disabled="isCreateCostDisabled"
            data-test-id="create-button"
            @clickbutton="isCreateModalOpen = true"
          >
            Add cost
          </ui-button>
        </div>
      </div>

      <div class="w-100 emobg-border-top-1 emobg-border-color-ground my-3" />

      <div class="d-flex flex-column align-items-center">
        <TableComponent
          :schema="settlementsDraftSchema"
          :data="costs"
          :empty-label="FALLBACK_MESSAGE.dash"
          class="w-100"
          data-test-id="settlements_draft-table"
        />
      </div>
    </ui-card>

    <ui-card data-test-id="settlements-card">
      <h2 class="mb-2 mt-1 emobg-font-weight-semibold">
        Settlements
      </h2>

      <div class="w-100 emobg-border-top-1 emobg-border-color-ground my-3" />

      <ui-alert
        :color="COLORS.primary"
        :icon="ICONS.infoFull"
        class="pb-3 d-block w-100"
      >
        <span class="emobg-font-weight-semibold">Special charge</span> can be managed on settlements with status
        <span class="emobg-font-weight-semibold">Created</span>
      </ui-alert>

      <TableComponent
        :schema="settlementsSchema"
        :data="settlements"
        :row-actions="settlementsActions"
        :empty-label="FALLBACK_MESSAGE.dash"
        data-test-id="settlements-table"
      />
    </ui-card>

    <CreateAdditionalCost
      :is-open="isCreateModalOpen"
      :close="() => isCreateModalOpen = false"
      :on-save="onSaveAdditionalCostLine"
      :costs="costs"
      :is-add-special-charge="isAddSpecialCharge"
      :is-edit-special-charge="isEditSpecialCharge"
      :settlement-special-charge="specialChargeSettlement"
    />

    <MuiModal
      v-model="isSendModalOpen"
      data-test-id="confirm-modal"
      backdrop-dismiss
    >
      <template slot="body">
        <div class="py-3">
          <h2 class="mb-3 emobg-font-weight-semibold">
            Create a settlement from these costs?
          </h2>
          <div class="emobg-color-ink-light">
            This cannot be undone
          </div>
        </div>
      </template>

      <template slot="footer">
        <div class="d-flex align-content-center justify-content-end w-100 p-3">
          <CancelButton
            data-test-id="cancel-button"
            @click="isSendModalOpen = false"
          />
          <ui-button
            :disabled="costsStatus.LOADING"
            data-test-id="send-button"
            @clickbutton="createSettlement"
          >
            Create
          </ui-button>
        </div>
      </template>
    </MuiModal>

    <MuiModal
      v-model="isRefundModalOpen"
      data-test-id="refund-modal"
      backdrop-dismiss
    >
      <template slot="body">
        <div class="py-3">
          <h2 class="mb-3 emobg-font-weight-semibold">
            Refund this settlement?
          </h2>
          <div class="emobg-color-ink-light">
            This cannot be undone
          </div>
        </div>
      </template>

      <template slot="footer">
        <div class="d-flex align-content-center justify-content-end w-100 p-3">
          <CancelButton
            data-test-id="cancel-button"
            @click="isRefundModalOpen = false"
          />
          <ui-button
            :disabled="refundStatus.LOADING"
            data-test-id="save-button"
            @clickbutton="refundSettlement"
          >
            Refund
          </ui-button>
        </div>
      </template>
    </MuiModal>
  </div>
</template>
