var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "BookingAuditComponent",
      staticStyle: { "overflow-wrap": "break-word" },
      attrs: { "data-test-id": "booking_audits" },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-6", attrs: { "data-test-id": "before_values" } },
          [
            _c("div", { staticClass: "emobg-caption-2 pb-2 emobg-color-ink" }, [
              _vm._v(" Before "),
            ]),
            _vm.isEmpty(_vm.before)
              ? _c("div", [
                  _vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " "),
                ])
              : _vm.areSameValues
              ? _c("div", [_vm._v(" No data change registered ")])
              : _vm._e(),
            _vm._l(_vm.changedItem(_vm.before), function (value, key) {
              return _c(
                "div",
                { key: `${key}_before` },
                [
                  value
                    ? _c("ContentCellComponent", {
                        staticClass: "mb-3",
                        attrs: { label: _vm.sentenceCase(key), value: value },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "col-6", attrs: { "data-test-id": "after_values" } },
          [
            _c("div", { staticClass: "emobg-caption-2 pb-2 emobg-color-ink" }, [
              _vm._v(" After "),
            ]),
            _vm.isEmpty(_vm.after)
              ? _c("div", [
                  _vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " "),
                ])
              : _vm.areSameValues
              ? _c("div", [_vm._v(" No data change registered ")])
              : _vm._e(),
            _vm._l(_vm.changedItem(_vm.after), function (value, key) {
              return _c(
                "div",
                { key: `${key}_after` },
                [
                  value
                    ? _c("ContentCellComponent", {
                        staticClass: "mb-3",
                        attrs: { label: _vm.sentenceCase(key), value: value },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }